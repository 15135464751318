import React  from 'react';
import { CRow } from '@coreui/react';
import CPagination from '../../../../../../migration/pagination/CPagination';

const TablePagination = ({ targetAccountsList, pagination, onPaginationChange, onChangeItemsPerPage }) => {
     const newTargetAccount = targetAccountsList
     const totalPages = newTargetAccount.length > 0 && newTargetAccount[0].totalCount ? Math.ceil(newTargetAccount[0].totalCount / pagination.pageSize) : 1;
     const isPaginate = newTargetAccount.length > 0;

     if (!isPaginate) return (<></>);

     return (
          <CRow className="report-table-bottom ta-table-bottom">
               <div className='d-flex align-items-center w-100'>
                    <CPagination
                         activePage={pagination.page}
                         pages={totalPages}
                         onActivePageChange={onPaginationChange}
                         className="report-table-page"
                    ></CPagination>
                    <div className="form-inline justify-content-sm-end ml-4">
                         <select className="form-control" onChange={onChangeItemsPerPage} defaultValue={pagination.pageSize} value={pagination.pageSize}>
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                         </select>
                         <label className="ml-2">Items per page</label>
                    </div>
               </div>
          </CRow>
     );
};

export default TablePagination;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector, useDispatch } from 'react-redux';
import { CButton, CRow, CCol } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
     setShowSetupGuidePopup,
     setIsFromGetStarted,
     setShowCreateAccountPopup,
     setShowVideo,
     setTour,
     setShowRoadmapMinimize,
     setSetupGuideRoadmapIncrease,
     setSetupGuideRoadmapDecrease,
     setDataSetupGuideDataCompleted,
     setChildSetupGuideDataCompleted,
     setSetupGuideRoadmap,
     setShowRoadMap,
     setShowTourFormRoadMap,
     setShowTourCompletedWizard,
     setConversionRules,
     setInitialConversionRules,
} from '../../../../actions/subscriber';
import { deepCopyArray, toastError } from '../../../../utils';
import { callTokenApi } from '../../../../apiCaller';
import {
     API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE,
     SEND_US_DATA_NOTIFICATION_VIDEO,
     SETUP_GUIDE_STEP,
     STEP_GUIDE,
     SUBSCRIBER_PATH,
     STEPS_WIZARD_CATEGORIES,
     API_CLIENT_ACCOUNT_SETUP_GUIDE_DATA,
     ROADMAP_GUIDESTEPS,
     STEPS_CUSTOM_FORM,
     ROADMAP_LEARN_ABOUT_ANALYTICS,
     // ROADMAP_WEB_CONVERSIONS,
     ROADMAP_LEARN_ABOUT_GOAL,
     ROADMAP_REVIEW_VISUALIZE_DATA,
     ROADMAP_CUSTOM_WEB_DATA,
     ROADMAP_REVEAL_PEOPLE,
     ROADMAP_ABOUT_CONSENT,
     STEPS_CONFIG_CONVERSION,
     API_CLIENT_ACCOUNT_RULES,
} from '../../../../constants';
import { GuideGetStarted } from './GuideGetStarted';
import { InstallGoals } from './InstallGoals';
import { CustomOptional } from './CustomOptional';
import { FormConversions } from './FormConversions';
import { LearnAboutData } from './LearnAboutData';
import { LearnAboutSendingData } from './LearnAboutSendingData';
// import { ConfigureDestination } from './ConfigureDestination';
import { TOURSTEPS } from '../../../../containers/subscriber/TheTour';
import Cookies from 'js-cookie';
import VideoPopupSetupGuide from './VideoPopupSetupGuide';
import { EndRoadmap } from './EndRoadmap';
import BoxFeature from './BoxFeature';
import { Message } from './Message';
import DefaultLoading from './DefaultLoading';
import CImg from '../../../migration/CImg';
import CSwitch from '../../../migration/CSwitch';

export const GUIDESTEPS = {
     guideGetStarted: {
          orderNumber: 0,
          name: 'guideGetStarted',
          content: [
               {
                    class: 'message',
                    text: "Hi Jane, welcome to ListenLayer! My name is Larry, and I'm here to make sure you accomplish your goals.",
               },
               {
                    class: 'message',
                    text: 'I realized you want to dive into the interface and get started! I strongly recommend you follow my lead to complete a few steps.',
               },
          ],
          featureSuccess: true,
          contentSuccess: {
               class: 'message',
               text: 'Click “NEXT” when you’re ready to dive in.',
          },
     },
     installAndLearn: {
          orderNumber: 1,
          name: 'installAndLearn',
          content: [
               {
                    class: 'message',
                    text: "We'll start with three simple steps. As you complete these, you'll learn about the platform and interface.",
                    iconLink: '/assets/images/setupGuide-icon.png',
               },
          ],
          feature: [
               {
                    title: 'Install ListenLayer',
                    code: 'installListenlayer',
                    videoLink: '',
                    timeVideo: '',
                    status: false,
                    btnText: 'Start',
                    onClick(dispatch, activeAccount) {
                         localStorage.setItem('installGoals', true);
                         window.localStorage.setItem('isRoadmap' + activeAccount.id, true);
                         dispatch(setShowRoadMap(true));
                         dispatch(setIsFromGetStarted(true));
                         dispatch(setShowCreateAccountPopup(true));
                    },
               },
               {
                    title: 'Learn About Goals',
                    code: 'learnAboutGoals',
                    videoLink: '',
                    timeVideo: '2 mins',
                    status: false,
                    btnText: 'Watch',
                    isVideo: true,
                    onClick(dispatch) {
                         dispatch(setShowVideo({ learnGoals: true }));
                    },
               },
               {
                    title: 'Learn About Analytics',
                    code: 'learnAboutInsights',
                    videoLink: '',
                    timeVideo: '2.2 mins',
                    status: false,
                    isVideo: true,
                    btnText: 'Watch',
                    onClick(dispatch) {
                         dispatch(setShowVideo({ installAndLearn: true }));
                    },
               },
          ],
          featureSuccess: false,
          contentSuccess: {
               class: 'message',
               text: "Nice work! Select NEXT when you're ready to move forward.",
          },
     },

     customImplementation: {
          orderNumber: 2,
          name: 'customImplementation',
          content: [
               {
                    class: 'message',
                    text: 'Great work!',
               },
               {
                    class: 'message',
                    text: 'Before we can configure your goals, it looks like you have enabled one or more Listeners that require customization.',
               },
               {
                    class: 'message',
                    text: 'Complete these steps to Continue.',
               },
          ],
          feature: [
               {
                    title: 'Configure Custom Forms',
                    code: 'configureCustomForms',
                    status: false,
                    videoLink: '',
                    timeVideo: '4 mins',
                    isSuccess: false,
                    btnText: 'Start',
                    contentTooltip: 'Configure Custom Form',
                    onClick(dispatch, activeAccount, history) {
                         dispatch(setShowSetupGuidePopup(false));
                         dispatch(setShowRoadMap(true));
                         const LeaveStepCustomForms = localStorage.getItem('LeaveStepCustomForms');
                         const stepData = { stepPause: 2, type: 'CustomForm' };
                         const nextStep =
                              LeaveStepCustomForms && LeaveStepCustomForms === 'true'
                                   ? TOURSTEPS.roadMapCustomForms.orderNumber
                                   : TOURSTEPS.menuListenerCustomForms.orderNumber;
                         // const stepComplete = activeAccount.currentStepCustomForms && activeAccount.currentStepCustomForms === STEPS_CUSTOM_FORM.manageCustomForms;
                         // currentStep là status hiện tại custom form nếu đã hoàn thành
                         if (activeAccount.currentStepCustomForms !== STEPS_CUSTOM_FORM.manageCustomForms) {
                              dispatch(setTour({ showTour: true, stepTour: nextStep }));
                              Cookies.set(`stepTour${activeAccount.id}`, nextStep, { expires: 365 });
                         } else {
                              dispatch(setTour({ showTour: true, stepTour: TOURSTEPS.targetCustomForms.orderNumber }));
                              Cookies.set(`stepTour${activeAccount.id}`, TOURSTEPS.targetCustomForms.orderNumber, { expires: 365 });
                         }
                         localStorage.setItem(`roadMapWizard${activeAccount.id}`, JSON.stringify(stepData));
                         dispatch(setShowTourCompletedWizard({ wizardCustomForm: true }));
                         dispatch(setShowTourFormRoadMap(true));
                         history.push(SUBSCRIBER_PATH.CUSTOM_HTML_FORMS.replace(':secondId', activeAccount.secondId));
                    },
               },
               // {
               //      title: 'Configure Pardot Forms',
               //      videoLink: '',
               //      timeVideo: '6 mins',
               //      isSuccess: false,
               //      btnText: 'Start',
               //      onClick(dispatch) {
               //           dispatch(
               //                setFeaturecustomImplementation({
               //                     ConfigurePardotForms: true,
               //                })
               //           );
               //      },
               // },
               // {
               //      title: 'Configure Squarespace Scheduling',
               //      videoLink: '',
               //      timeVideo: '6 mins',
               //      isSuccess: false,
               //      btnText: 'Start',
               //      onClick(dispatch) {
               //           dispatch(
               //                setFeaturecustomImplementation({
               //                     ConfigureSquarespaceScheduling: true,
               //                })
               //           );
               //      },
               // },
               // {
               //      title: 'Configure Shopify',
               //      videoLink: '',
               //      timeVideo: '6 mins',
               //      isSuccess: false,
               //      btnText: 'Start',
               //      onClick(dispatch) {
               //           dispatch(
               //                setFeaturecustomImplementation({
               //                     ConfigureShopify: true,
               //                })
               //           );
               //      },
               // },
               // {
               //      title: 'Configure WooCommerce',
               //      videoLink: '',
               //      timeVideo: '6 mins',
               //      isSuccess: false,
               //      btnText: 'Start',
               //      onClick(dispatch) {
               //           dispatch(
               //                setFeaturecustomImplementation({
               //                     ConfigureWooCommerce: true,
               //                })
               //           );
               //      },
               // },
          ],
          featureSuccess: false,
          contentSuccess: {
               class: 'message',
               text: "Nice work! Select NEXT when you're ready to move forward.",
          },
     },

     forCategorization: {
          orderNumber: 3,
          name: 'forCategorization',
          content: [
               {
                    class: 'message',
                    text: 'Cool! We have the basics out of the way.',
               },
               {
                    class: 'message',
                    text: "Now that you understand Goals, and the Insights ListenLayer can generate, let's configure some settings specifically for you.",
               },
               {
                    class: 'message',
                    text: "It may feel overwhelming to build your goals now! Don't worry, these steps simply introduce you to this important part of the platform... you can adjust the settings at any time in the future!",
               },
               {
                    class: 'message',
                    text: 'Go ahead and complete these steps so we can move forward.',
                    history: 'notShow',
               },
          ],
          feature: [
               {
                    title: 'Categorize Your Forms',
                    status: false,
                    code: 'categorizeYourForms',
                    videoLink: '',
                    timeVideo: '4 mins',
                    isSuccess: false,
                    btnText: 'Start',
                    onClick(dispatch, activeAccount, history) {
                         localStorage.setItem('forCategorization', true);
                         const stepStartFormCategory =
                              activeAccount.currentStepFormCategories !== STEPS_WIZARD_CATEGORIES.manageFormCategories &&
                              activeAccount.currentStepFormCategories !== STEPS_WIZARD_CATEGORIES.yourDone;
                         // const stepComplete = activeAccount.currentStepFormCategories === STEPS_WIZARD_CATEGORIES.manageFormCategories || activeAccount.currentStepFormCategories === STEPS_WIZARD_CATEGORIES.yourDone
                         const stepStartAgain =
                              activeAccount.currentStepFormCategories === STEPS_WIZARD_CATEGORIES.manageFormCategories ||
                              activeAccount.currentStepFormCategories === STEPS_WIZARD_CATEGORIES.yourDone;
                         dispatch(setShowSetupGuidePopup(false));
                         dispatch(setShowRoadMap(true));
                         const nextStep = activeAccount.skipWizardFormCategory
                              ? TOURSTEPS.roadMapFormCategory.orderNumber
                              : TOURSTEPS.menuFormCategory.orderNumber;
                         if (stepStartFormCategory) {
                              dispatch(setTour({ showTour: true, stepTour: nextStep }));
                              Cookies.set(`stepTour${activeAccount.id}`, nextStep, { expires: 365 });
                              dispatch(setShowTourFormRoadMap(true));
                         } else if (stepStartAgain) {
                              dispatch(setTour({ showTour: true, stepTour: TOURSTEPS.requiringActionFormCategoryWizard.orderNumber }));
                              Cookies.set(`stepTour${activeAccount.id}`, TOURSTEPS.requiringActionFormCategoryWizard.orderNumber, { expires: 365 });
                         }
                         dispatch(setShowTourCompletedWizard({ wizardFormCategory: true }));
                         const stepData = { stepPause: 3, type: 'Category' };
                         localStorage.setItem(`roadMapWizard${activeAccount.id}`, JSON.stringify(stepData));
                         history.push(SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId));
                    },
               },
               {
                    title: 'Build Web Conversions',
                    code: 'buildWebConversions',
                    status: false,
                    videoLink: '',
                    timeVideo: '',
                    isSuccess: false,
                    btnText: 'Start',
                    btnWaiting: 'Waiting',
                    isWaiting: true,
                    contentWait: 'Please complete the step above first',
                    onClick(dispatch, activeAccount, history, isConversionRule) {
                         dispatch(setShowSetupGuidePopup(false));
                         dispatch(setShowRoadMap(true));
                         if (activeAccount.currentStepConversion === STEPS_CONFIG_CONVERSION.configConversionDone) {
                              if (!isConversionRule) {
                                   const nextStep = TOURSTEPS.previewTrigger.orderNumber;

                                   dispatch(setTour({ showTour: true, stepTour: nextStep }));
                                   Cookies.set(`stepTour${activeAccount.id}`, nextStep, { expires: 365 });
                              } else {
                                   const nextStep = TOURSTEPS.previewConfigureConversion.orderNumber;

                                   dispatch(setTour({ showTour: true, stepTour: nextStep }));
                                   Cookies.set(`stepTour${activeAccount.id}`, nextStep, { expires: 365 });
                              }
                         } else {
                              const nextStep = TOURSTEPS.configureConversion.orderNumber;

                              dispatch(setTour({ showTour: true, stepTour: nextStep }));
                              Cookies.set(`stepTour${activeAccount.id}`, nextStep, { expires: 365 });
                         }
                         const stepData = { stepPause: 3, type: 'Conversion' };
                         localStorage.setItem(`roadMapWizard${activeAccount.id}`, JSON.stringify(stepData));

                         history.push(SUBSCRIBER_PATH.GOALS_WEB_CONVERSIONS.replace(':secondId', activeAccount.secondId));
                    },
               },
          ],
          featureSuccess: false,
          contentSuccess: {
               class: 'message',
               text: "Nice work! Select NEXT when you're ready to move forward.",
          },
     },
     dataEnrichment: {
          orderNumber: 4,
          name: 'dataEnrichment',
          content: [
               {
                    class: 'message',
                    text: "Let's take a moment to learn about data Enrichment.",
               },
               {
                    class: 'message',
                    text: 'ListenLayer optionally allows you to identify real people and companies in your analytics data, and to enrich your data in other ways.',
               },
               {
                    class: 'message',
                    text: 'These steps should take less than 3 minutes to complete!',
               },
          ],
          feature: [
               {
                    title: 'Preview & Visualize Your Data',
                    status: false,
                    code: 'previewVisualizeYourData',
                    videoLink: '',
                    timeVideo: '4.5 mins',
                    isSuccess: false,
                    btnText: 'Watch',
                    onClick(dispatch) {
                         dispatch(setShowVideo({ PreviewVisualize: true }));
                    },
               },
               {
                    title: 'Learn How to Reveal People',
                    status: false,
                    code: 'learnHowToRevealPeople',
                    videoLink: '',
                    timeVideo: '4 mins',
                    isSuccess: false,
                    btnText: 'Watch',
                    onClick(dispatch) {
                         dispatch(setShowVideo({ enrichData: true }));
                    },
               },
               {
                    title: 'Learn About Custom Web Data',
                    code: 'learnAboutCustomWebData',
                    status: false,
                    videoLink: '',
                    timeVideo: '6 mins',
                    isSuccess: false,
                    btnText: 'Watch',
                    onClick(dispatch) {
                         dispatch(setShowVideo({ customWebData: true }));
                    },
               },
          ],
          featureSuccess: false,
          contentSuccess: {
               class: 'message',
               text: "Nice work! Select NEXT when you're ready to move forward.",
          },
     },
     sendingData: {
          orderNumber: 5,
          name: 'sendingData',
          content: [
               {
                    class: 'message',
                    text: "Okay, it's time to learn about sending data.",
               },
               {
                    class: 'message',
                    text: 'Take a few minutes to learn about how we send data to destinations and how you can use ListenLayer to comply with Regulations',
               },
          ],
          feature: [
               {
                    title: 'Learn About Destinations',
                    videoLink: '',
                    status: false,
                    code: 'learnAboutDestinations',
                    timeVideo: '',
                    isSuccess: false,
                    btnText: 'Start',
                    onClick(dispatch, activeAccount, history) {
                         dispatch(setShowSetupGuidePopup(false));
                         dispatch(setShowRoadMap(true));
                         const nextStep = TOURSTEPS.popupDestinationsWizard.orderNumber;
                         const stepData = { stepPause: 5, type: 'Destination' };
                         dispatch(setTour({ showTour: true, stepTour: nextStep }));
                         Cookies.set(`stepTour${activeAccount.id}`, nextStep, { expires: 365 });
                         localStorage.setItem(`roadMapWizard${activeAccount.id}`, JSON.stringify(stepData));
                         dispatch(setShowTourFormRoadMap(true));

                         history.push(SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId));
                    },
               },
               {
                    title: 'Learn About Consent',
                    videoLink: '',
                    status: false,
                    code: 'learnAboutConsent',
                    timeVideo: '4 mins',
                    isSuccess: false,
                    btnText: 'Watch',
                    btnWaiting: 'Waiting',
                    contentWait: 'Please complete the step above first',
                    isWaiting: true,
                    onClick(dispatch) {
                         dispatch(setShowVideo({ learnConsent: true }));
                    },
                    // subContent: [
                    //      {
                    //           text: "Nice work, you're making good progress.",
                    //      },
                    // {
                    //      text: "If you're a healthcare company and want to learn about HIPAA compliance, optionally watch this next video.",
                    // },
                    // ],
               },
          ],
          // featureSecond: [
          //      {
          //           title: 'Learn About HIPAA Compliance',
          //           code: 'learnAboutHIPAACompliance',
          //           status: false,
          //           videoLink: '',
          //           timeVideo: '4 mins',
          //           isSuccess: false,
          //           btnText: 'Watch',
          //           onClick(dispatch) {
          //                dispatch(setShowVideo({ learnHIPAA: true }));
          //           },
          //      },
          // ],
          continueFeature: false,
          featureSuccess: false,
          contentSuccess: {
               class: 'message',
               text: "Nice work! Select NEXT when you're ready to move forward.",
          },
     },
     destination: {
          orderNumber: 6,
          name: 'destination',
          content: [
               {
                    class: 'message',
                    text: "Let's configure a few destinations. This is where you'll really get to experience the power of a centralized data strategy!",
               },
          ],
          feature: [
               {
                    title: 'Configure GA4',
                    code: 'configureGA4',
                    status: false,
                    videoLink: '',
                    timeVideo: '4 mins',
                    isSuccess: false,
                    config: 'Google Analytics 4',
                    name: 'destination',
                    btnText: 'Watch',
                    onClick(dispatch) {
                         dispatch(setShowVideo({ GA4: true }));
                    },
               },
               {
                    title: 'Configure Facebook Ads',
                    videoLink: '',
                    status: false,
                    code: 'configureFacebookAds',
                    timeVideo: '4 mins',
                    isSuccess: false,
                    config: 'Facebook Ads',
                    name: 'destination',
                    btnText: 'Watch',
                    onClick(dispatch) {
                         dispatch(setShowVideo({ FBAds: true }));
                    },
               },
               {
                    title: 'Configure Google Ads',
                    status: false,
                    code: 'configureGoogleAds',
                    videoLink: '',
                    timeVideo: '4 mins',
                    isSuccess: false,
                    config: 'Google Ads',
                    name: 'destination',
                    btnText: 'Watch',
                    onClick(dispatch) {
                         dispatch(setShowVideo({ GGAds: true }));
                    },
               },
               {
                    title: 'Configure Linkedin Ads',
                    status: false,
                    code: 'configureLinkedinAds',
                    videoLink: '',
                    timeVideo: '4 mins',
                    isSuccess: false,
                    config: 'LinkedIn Ads',
                    name: 'destination',
                    btnText: 'Watch',
                    onClick(dispatch) {
                         dispatch(setShowVideo({ LinkedinAds: true }));
                    },
               },
               {
                    title: 'Configure Microsoft Ads',
                    code: 'configureMicrosoftAds',
                    status: false,
                    videoLink: '',
                    timeVideo: '4 mins',
                    isSuccess: false,
                    config: 'Microsoft Ads',
                    name: 'destination',
                    btnText: 'Watch',
                    onClick(dispatch) {
                         dispatch(setShowVideo({ MicrosoftAds: true }));
                    },
               },
          ],
          continueFeature: false,
          featureSuccess: false,
          contentSuccess: {
               class: 'message',
               text: "Nice work! Select NEXT when you're ready to move forward.",
          },
     },
};

export const GuideBody = ({ children, show = false, nextTour, featureSuccess = false }) => {
     return (
          <div className={`tour-guide ${show ? ' show' : ''} setup-guide`}>
               {show && children && (
                    <div className="tour-guide-content">
                         {children}
                         {featureSuccess === true && (
                              <div className="buttons justify-content-end">
                                   <CButton className="next-button" onClick={() => nextTour()}>
                                        Next
                                   </CButton>
                              </div>
                         )}
                    </div>
               )}
          </div>
     );
};

export const GuideBodyHistory = ({ children, style }) => {
     return (
          <div style={style} className={`tour-guide show setup-guide`}>
               {children && <div className="tour-guide-content">{children}</div>}
          </div>
     );
};

const GetComponent = ({ props }) => {
     const { currentStepRoadmap, dataStepCompleted } = props;

     for (let key in GUIDESTEPS) {
          const componentData = { ...GUIDESTEPS[key] };

          if (componentData && componentData.feature) {
               const dataCompleted = dataStepCompleted[key] || {};

               componentData.feature.forEach((feature) => {
                    feature.status = dataCompleted[feature.code] || false;
               });

               if (componentData.featureSecond) {
                    componentData.featureSecond.forEach((feature) => {
                         feature.status = dataCompleted[feature.code] || false;
                    });
               }

               GUIDESTEPS[key] = componentData;
          }
     }

     switch (parseInt(currentStepRoadmap)) {
          case STEP_GUIDE.STEP0:
               return <GuideGetStarted {...props} featureSuccess={true} />;
          case STEP_GUIDE.STEP1:
               return <InstallGoals {...props} installAndLearn={GUIDESTEPS.installAndLearn} />;
          case STEP_GUIDE.STEP2:
               return <CustomOptional {...props} dataFeature={GUIDESTEPS.customImplementation} />;
          case STEP_GUIDE.STEP3:
               return <FormConversions {...props} dataFeature={GUIDESTEPS.forCategorization} />;
          case STEP_GUIDE.STEP4:
               return <LearnAboutData {...props} dataFeature={GUIDESTEPS.dataEnrichment} />;
          case STEP_GUIDE.STEP5:
               return <LearnAboutSendingData {...props} dataFeature={GUIDESTEPS.sendingData} />;
          // case STEP_GUIDE.STEP6:
          //      return <ConfigureDestination {...props} dataFeature={GUIDESTEPS.destination} />;
          case STEP_GUIDE.STEP6:
               return <EndRoadmap {...props} />;
          default:
               return <DefaultLoading />;
     }
};

const SetupGuidePopup = ({ show }) => {
     const dispatch = useDispatch();
     const showBlockSetupGuidePopup = useSelector((state) => state.subscriber.showBlockSetupGuidePopup);
     const setShowRoadmapMininize = useSelector((state) => state.subscriber.setShowRoadmapMininize);
     const activeAccountId = useSelector((state) => state.subscriber.activeAccount.id);
     const [isShowHistory, setIsShowHistory] = useState(false);
     const [isShowminimize, setIsShowMinimize] = useState(false);
     const currentStepRoadmap = useSelector((state) => state.subscriber.set_setup_guide_roadmap);
     const dataStepCompleted = useSelector((state) => state.subscriber.setupGuideDataCompleted);
     const isShowCustomForm = useSelector((state) => state.subscriber.isShowCustomForm);
     const [historyData, setHistoryData] = useState([]);
     const newCompleteSteps = SETUP_GUIDE_STEP.filter((item) => historyData.includes(item));
     const showVideo = useSelector((state) => state.subscriber.setShowVideo);
     const activeListeners = useSelector((state) => state.subscriber.activeListeners);
     const isCustomForm = activeListeners.find((item) => item.alias === 'custom-html-forms');
     const accounts = useSelector((state) => state.subscriber.accounts);
     const name = useSelector((state) => state.theme.user.firstName);
     const showAnimationRoadmap = localStorage.getItem('showAnimationRoadmap');
     const onCloseModal = () => {
          dispatch(setShowSetupGuidePopup(false));
          dispatch(setShowRoadmapMinimize(true));
     };
     const videoRef = useRef();

     const handleFetchRule = useCallback(() => {
          callTokenApi(`${API_CLIENT_ACCOUNT_RULES}/${activeAccountId}?group=conversions`, 'GET').then((response) => {
               if (response.status === 200) {
                    const { rules } = response.data;
                    dispatch(setConversionRules(rules));
                    dispatch(setInitialConversionRules(deepCopyArray(rules)));
               } else {
                    toastError(response);
               }
          });
     }, [activeAccountId, dispatch]);
     useEffect(handleFetchRule, []); // eslint-disable-line react-hooks/exhaustive-deps

     const getComponentHistory = (tourStepData, name, dataStatus) => {
          switch (tourStepData.name) {
               case 'guideGetStarted':
                    return (
                         <div className="content">
                              <Message
                                   text={`Hi ${name}, welcome to ListenLayer! My name is Larry, and I'm here to make sure you accomplish your goals.`}
                              />
                              <Message
                                   text={
                                        'I realized you want to dive into the interface and get started! I strongly recommend you follow my lead to complete a few steps.'
                                   }
                              />
                         </div>
                    );

               // case 'destination':
               //      if (accountDestination.length === 1)
               //           break
               //      return (
               //           <div className='content'>
               //                <Message text={"Let's configure a few destinations. This is where you'll really get to experience the power of a centralized data strategy!"} />
               //                {(accountDestination.length === 2 || accountDestination.length === 3) && (
               //                     <>
               //                          {tourStepData.feature.filter((item) => accountDestination.find((des) => des.name === item.config)).map((feature, index) => (
               //                               <BoxFeature key={index} feature={feature} isSuccess={dataStatus[feature.code]} />
               //                          ))}
               //                     </>
               //                )}
               //                {accountDestination.length > 3 && (
               //                     <>
               //                          {tourStepData.feature.filter((item) => accountDestination.find((des) => des.name === item.config)).map((feature, index) => {
               //                               if (index === 2) {
               //                                    return (
               //                                         <>
               //                                              <Message text={"Here are the configurations for the other destinations you've enabled."} />
               //                                              <BoxFeature key={index} feature={feature} isSuccess={dataStatus[feature.code]} />
               //                                         </>
               //                                    )
               //                               }
               //                               return (<BoxFeature key={index} feature={feature} isSuccess={dataStatus[feature.code]} />)
               //                          }

               //                          )}
               //                     </>
               //                )}
               //           </div>
               //      );
               case 'endRoadmap':
                    return (
                         <div className="content">
                              <Message
                                   text={
                                        "Congrats! You've completed the initial configuration of your account. You can always revisit this conversation to re-watch the videos."
                                   }
                              />
                              <Message text={'And remember, you can reach out to our support team with any questions you might have!'} />
                         </div>
                    );

               default:
                    if (tourStepData.content && tourStepData.feature) {
                         if (tourStepData.name === 'customImplementation' && !isCustomForm) break;
                         return (
                              <div className="content">
                                   {tourStepData.content.length > 0 &&
                                        tourStepData.content.map(
                                             (content, index) =>
                                                  content.history !== 'notShow' && (
                                                       <div key={index} className={content.class}>
                                                            {content.text}
                                                            {content.iconLink && <CImg src={content.iconLink} width={17} height={17} />}
                                                       </div>
                                                  )
                                        )}
                                   {tourStepData.feature.length > 0 &&
                                        tourStepData.feature.map((feature, index) => {
                                             const offsetStatus =
                                                  index > 0 ? tourStepData.feature[index - 1].status : tourStepData.feature[index].status;
                                             return (
                                                  <>
                                                       <BoxFeature
                                                            key={index}
                                                            feature={feature}
                                                            isActiveConversion={offsetStatus}
                                                            isSuccess={dataStatus ? dataStatus[feature.code] : false}
                                                       />
                                                  </>
                                             );
                                        })}
                                   {/* {countSendingData === 2 && (
                                        tourStepData.featureSecond && tourStepData.featureSecond.map((feature, index) => {
                                             const offsetStatus = index > 0 ? tourStepData.feature[index - 1].status : tourStepData.feature[index].status;

                                             return (
                                                  <>
                                                       <Message text={"If you're a healthcare company and want to learn about HIPAA compliance, optionally watch this next video."} />
                                                       <BoxFeature key={feature.title} feature={feature} isActiveConversion={offsetStatus} isSuccess={dataStatus[feature.code]} />
                                                  </>
                                             )
                                        })
                                   )} */}
                              </div>
                         );
                    } else {
                         return (
                              <div className="content">
                                   {tourStepData.content.length > 0 &&
                                        tourStepData.content.map((content, index) => (
                                             <div key={index} className={content.class}>
                                                  {content.text}
                                                  {content.iconLink && <CImg src={content.iconLink} width={17} height={17} />}
                                             </div>
                                        ))}
                              </div>
                         );
                    }
          }
     };
     const onBackStep = (x = 1) => {
          // if (isCustomForm) {
          //      if (accountDestination.length === 1 && currentStepRoadmap === 7) {
          //                dispatch(setSetupGuideRoadmapDecrease(x + 1))
          //      } else {
          //           dispatch(setSetupGuideRoadmapDecrease(x))
          //      }
          // } else {
          //      if (accountDestination.length === 1) {
          //           if (currentStepRoadmap === 7) {
          //                dispatch(setSetupGuideRoadmapDecrease(x + 1))
          //           } else {
          //                dispatch(setSetupGuideRoadmapDecrease(x))
          //           }
          //      } else {
          //           if (currentStepRoadmap === 3) {
          //                dispatch(setSetupGuideRoadmapDecrease(x + 1))
          //           } else {
          //                dispatch(setSetupGuideRoadmapDecrease(x))
          //           }

          //      }
          // }

          localStorage.removeItem('showAnimationRoadmap');

          if (isCustomForm) {
               dispatch(setSetupGuideRoadmapDecrease(x));
          } else {
               if (currentStepRoadmap === 3) {
                    dispatch(setSetupGuideRoadmapDecrease(x + 1));
               } else {
                    dispatch(setSetupGuideRoadmapDecrease(x));
               }
          }
     };

     const nextTour = (x = 1) => {
          dispatch(setSetupGuideRoadmapIncrease(x));
     };

     const handleShowAndHidePreview = () => {
          if (show) {
               setTimeout(() => document.body.classList.add('no-scroll'), 0);
          } else {
               document.body.classList.remove('no-scroll');
          }

          return () => document.body.classList.remove('no-scroll');
     };

     useEffect(handleShowAndHidePreview, [show]);

     useEffect(() => {
          if (!dataStepCompleted) {
               callTokenApi(`${API_CLIENT_ACCOUNT_SETUP_GUIDE_DATA}/${activeAccountId}`, 'GET').then((response) => {
                    if (response.status === 200) {
                         const result = response.data.data || {};
                         const completeStepArr = result['completedSteps'];
                         let currentStep = 0;

                         if (completeStepArr && Object.keys(completeStepArr).length > 0) {
                              for (let key in completeStepArr) {
                                   if (ROADMAP_GUIDESTEPS[key] && ROADMAP_GUIDESTEPS[key].orderNumber > currentStep) {
                                        currentStep = ROADMAP_GUIDESTEPS[key].orderNumber;
                                   }
                              }
                              if (!isCustomForm && currentStep === 1) {
                                   currentStep = currentStep + 1;
                              }
                              dispatch(setSetupGuideRoadmap(currentStep + 1));
                         } else {
                              dispatch(setSetupGuideRoadmap(0));
                         }

                         dispatch(setDataSetupGuideDataCompleted(result));
                         if (result['installAndLearn'] && Object.keys(result['installAndLearn']).length >= 2) {
                              setIsShowMinimize(true);
                         }
                    } else {
                         toastError(response);
                    }
               });
          } else {
               if (dataStepCompleted['installAndLearn'] && Object.keys(dataStepCompleted['installAndLearn']).length >= 2) {
                    setIsShowMinimize(true);
               }
          }

          if (accounts.length > 0) {
               setIsShowMinimize(true);
          }

          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [activeAccountId, dataStepCompleted]);

     return (
          <>
               <div
                    className={`screen-guide${isShowCustomForm ? ' roadmap-guide' : ''}${
                         setShowRoadmapMininize && !showBlockSetupGuidePopup ? ' minimize' : ''
                    }`}
               >
                    <div className="modal overflow-auto fade show d-block">
                         <div className="full-screen-wrapper setup-guide">
                              <div className={`setup-guide ${isShowHistory ? 'scroll' : ''}`}>
                                   <CRow className="d-flex justify-content-between align-items-center setup-guide-header">
                                        <CButton
                                             className={`btn-back back relative d-flex align-items-center ${
                                                  currentStepRoadmap === 0 || isShowHistory ? 'visible-hidden' : ''
                                             }`}
                                             onClick={() => onBackStep()}
                                        >
                                             <i className="fal fa-angle-left"></i>
                                             Go Back
                                        </CButton>
                                        <CIcon icon="logo-dark" height={34} className="guide" />
                                        <CButton className={`minimize ${isShowminimize === false ? 'visible-hidden' : ''}`} onClick={onCloseModal}>
                                             <CImg src="/assets/images/icon-minimize.png" className="mr-2" width="13" height="13" />
                                             Minimize
                                        </CButton>
                                   </CRow>
                                   <div className="pricing-header guide">
                                        <CRow className="guide-row">
                                             <CCol md="1"></CCol>
                                             <CCol md="11">
                                                  <h1
                                                       className={`selectpackage roadmap ${
                                                            showAnimationRoadmap && (currentStepRoadmap === -1 || currentStepRoadmap === 0)
                                                                 ? 'animate__animated animate__fadeIn animate__delay-1s'
                                                                 : ''
                                                       }`}
                                                  >
                                                       ListenLayer Quickstart Guide
                                                  </h1>
                                                  <h6
                                                       className={`selectdecription ${
                                                            showAnimationRoadmap && (currentStepRoadmap === -1 || currentStepRoadmap === 0)
                                                                 ? 'animate__animated animate__fadeIn animate__delay-2s'
                                                                 : ''
                                                       }`}
                                                  >
                                                       We want you to experience Data Freedom - the ability to have an accurate, planned, documented,
                                                       full-funnel, enriched measurement strategy - so you can achieve better marketing results.
                                                  </h6>
                                             </CCol>
                                        </CRow>
                                   </div>
                                   <div className={`guide-body`}>
                                        {isShowHistory ? (
                                             <CRow className="guide-row">
                                                  <CCol md="1">
                                                       <CImg src="/assets/images/avtAdSetupGuide.png" height="60" width="60" />
                                                  </CCol>
                                                  <CCol md="11">
                                                       {newCompleteSteps.map((item, index) => {
                                                            let tourStepData = { ...GUIDESTEPS, ...{ endRoadmap: { name: 'endRoadmap' } } }[item];
                                                            return (
                                                                 <>
                                                                      <GuideBodyHistory
                                                                           key={index}
                                                                           attribute={tourStepData}
                                                                           style={{ position: 'relative', zIndex: 10 - index }}
                                                                      >
                                                                           {getComponentHistory(tourStepData, name, dataStepCompleted[item])}
                                                                      </GuideBodyHistory>
                                                                 </>
                                                            );
                                                       })}
                                                  </CCol>
                                             </CRow>
                                        ) : (
                                             <>
                                                  {dataStepCompleted && (
                                                       <GetComponent
                                                            props={{
                                                                 dataStepCompleted: dataStepCompleted,
                                                                 currentStepRoadmap,
                                                                 nextTour,
                                                                 activeAccountId,
                                                            }}
                                                       />
                                                  )}
                                             </>
                                        )}
                                   </div>

                                   {currentStepRoadmap >= STEP_GUIDE.STEP2 && (
                                        <div className="footer setup-guide-footer text-center d-flex justify-content-center align-items-end">
                                             <CSwitch
                                                  size="sm"
                                                  color={'success'}
                                                  shape="pill"
                                                  tabIndex="0"
                                                  onChange={(e) => {
                                                       setIsShowHistory(e.target.checked);
                                                       if (e.target.checked) {
                                                            const completesStep = Object.keys({ ...dataStepCompleted.completedSteps });
                                                            let currentStep = {};
                                                            if (
                                                                 completesStep.length > 3 &&
                                                                 SETUP_GUIDE_STEP[completesStep.length - 1] === 'customImplementation'
                                                            ) {
                                                                 const step = SETUP_GUIDE_STEP.indexOf(completesStep[completesStep.length - 2]);
                                                                 currentStep = { [SETUP_GUIDE_STEP[step + 1]]: true };
                                                            } else {
                                                                 const step = SETUP_GUIDE_STEP.indexOf(completesStep[completesStep.length - 1]);
                                                                 currentStep = { [SETUP_GUIDE_STEP[step + 1]]: true };
                                                            }

                                                            for (let key in dataStepCompleted) {
                                                                 if (Object.keys(dataStepCompleted[key]).length !== 0) {
                                                                      currentStep = { ...currentStep, ...{ [key]: true } };
                                                                 }
                                                            }

                                                            const dataComplete = Object.keys({
                                                                 ...dataStepCompleted.completedSteps,
                                                                 ...{ [SETUP_GUIDE_STEP[currentStepRoadmap]]: true, customImplementation: true },
                                                                 ...currentStep,
                                                            });
                                                            setHistoryData(dataComplete);
                                                       }
                                                       // dispatch(setParentSetupGuideDataCompleted({forCategorization: true }));
                                                  }}
                                             />
                                             <span style={{ marginLeft: '10px' }}>Display Full Guide History</span>
                                        </div>
                                   )}
                              </div>
                         </div>
                    </div>
               </div>

               <div className="video-setupGuide">
                    {showVideo.installAndLearn && (
                         <VideoPopupSetupGuide
                              videoRef={videoRef}
                              show={showVideo.installAndLearn}
                              onCancel={() => {
                                   dispatch(setShowVideo({ installAndLearn: false }));
                              }}
                              onSuccess={() => {
                                   const newData = {
                                        accountId: activeAccountId,
                                        step: 'installAndLearn',
                                        data: {
                                             learnAboutInsights: true,
                                        },
                                   };

                                   callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                                        if (response.status === 200) {
                                             dispatch(
                                                  setChildSetupGuideDataCompleted({
                                                       key: 'installAndLearn',
                                                       data: {
                                                            learnAboutInsights: true,
                                                       },
                                                  })
                                             );
                                        } else {
                                             toastError(response);
                                        }
                                   });
                              }}
                              vimeoLink={ROADMAP_LEARN_ABOUT_ANALYTICS}
                              headline={'Learn About Analytics'}
                         />
                    )}
                    {showVideo.learnGoals && (
                         <VideoPopupSetupGuide
                              videoRef={videoRef}
                              show={showVideo.learnGoals}
                              onCancel={() => {
                                   dispatch(setShowVideo({ learnGoals: false }));
                              }}
                              onSuccess={() => {
                                   const newData = {
                                        accountId: activeAccountId,
                                        step: 'installAndLearn',
                                        data: {
                                             learnAboutGoals: true,
                                        },
                                   };
                                   callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                                        if (response.status === 200) {
                                             dispatch(
                                                  setChildSetupGuideDataCompleted({
                                                       key: 'installAndLearn',
                                                       data: {
                                                            learnAboutGoals: true,
                                                       },
                                                  })
                                             );
                                        } else {
                                             toastError(response);
                                        }
                                   });
                              }}
                              vimeoLink={ROADMAP_LEARN_ABOUT_GOAL}
                              headline={'Learn About Goals'}
                         />
                    )}
                    {showVideo.learnConsent && (
                         <VideoPopupSetupGuide
                              videoRef={videoRef}
                              show={showVideo.learnConsent}
                              onCancel={() => {
                                   dispatch(setShowVideo({ learnConsent: false }));
                              }}
                              onSuccess={() => {
                                   const newData = {
                                        accountId: activeAccountId,
                                        step: 'sendingData',
                                        data: {
                                             learnAboutConsent: true,
                                        },
                                   };
                                   callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                                        if (response.status === 200) {
                                             dispatch(
                                                  setChildSetupGuideDataCompleted({
                                                       key: 'sendingData',
                                                       data: {
                                                            learnAboutConsent: true,
                                                       },
                                                  })
                                             );
                                        } else {
                                             toastError(response);
                                        }
                                   });
                              }}
                              vimeoLink={ROADMAP_ABOUT_CONSENT}
                              headline={'Learn About Consent'}
                         />
                    )}
                    {showVideo.PreviewVisualize && (
                         <VideoPopupSetupGuide
                              videoRef={videoRef}
                              show={showVideo.PreviewVisualize}
                              onCancel={() => {
                                   dispatch(setShowVideo({ PreviewVisualize: false }));
                              }}
                              onSuccess={() => {
                                   const newData = {
                                        accountId: activeAccountId,
                                        step: 'dataEnrichment',
                                        data: {
                                             previewVisualizeYourData: true,
                                        },
                                   };
                                   callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                                        if (response.status === 200) {
                                             dispatch(
                                                  setChildSetupGuideDataCompleted({
                                                       key: 'dataEnrichment',
                                                       data: {
                                                            previewVisualizeYourData: true,
                                                       },
                                                  })
                                             );
                                        } else {
                                             toastError(response);
                                        }
                                   });
                              }}
                              vimeoLink={ROADMAP_REVIEW_VISUALIZE_DATA}
                              headline={'Preview & Visualize Your Data'}
                         />
                    )}
                    {showVideo.enrichData && (
                         <VideoPopupSetupGuide
                              videoRef={videoRef}
                              show={showVideo.enrichData}
                              onCancel={() => {
                                   dispatch(setShowVideo({ enrichData: false }));
                              }}
                              onSuccess={() => {
                                   const newData = {
                                        accountId: activeAccountId,
                                        step: 'dataEnrichment',
                                        data: {
                                             learnHowToRevealPeople: true,
                                        },
                                   };
                                   callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                                        if (response.status === 200) {
                                             dispatch(
                                                  setChildSetupGuideDataCompleted({
                                                       key: 'dataEnrichment',
                                                       data: {
                                                            learnHowToRevealPeople: true,
                                                       },
                                                  })
                                             );
                                        } else {
                                             toastError(response);
                                        }
                                   });
                              }}
                              vimeoLink={ROADMAP_REVEAL_PEOPLE}
                              headline={'Learn How to Reveal People'}
                         />
                    )}
                    {showVideo.customWebData && (
                         <VideoPopupSetupGuide
                              videoRef={videoRef}
                              show={showVideo.customWebData}
                              onCancel={() => {
                                   dispatch(setShowVideo({ customWebData: false }));
                              }}
                              onSuccess={() => {
                                   const newData = {
                                        accountId: activeAccountId,
                                        step: 'dataEnrichment',
                                        data: {
                                             learnAboutCustomWebData: true,
                                        },
                                   };
                                   callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                                        if (response.status === 200) {
                                             dispatch(
                                                  setChildSetupGuideDataCompleted({
                                                       key: 'dataEnrichment',
                                                       data: {
                                                            learnAboutCustomWebData: true,
                                                       },
                                                  })
                                             );
                                        } else {
                                             toastError(response);
                                        }
                                   });
                              }}
                              vimeoLink={ROADMAP_CUSTOM_WEB_DATA}
                              headline={'Learn About Custom Web Data'}
                         />
                    )}
                    {showVideo.GA4 && (
                         <VideoPopupSetupGuide
                              videoRef={videoRef}
                              show={showVideo.GA4}
                              onCancel={() => {
                                   dispatch(setShowVideo({ GA4: false }));
                              }}
                              onSuccess={() => {
                                   const newData = {
                                        accountId: activeAccountId,
                                        step: 'destination',
                                        data: {
                                             configureGA4: true,
                                        },
                                   };
                                   callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                                        if (response.status === 200) {
                                             dispatch(
                                                  setChildSetupGuideDataCompleted({
                                                       key: 'destination',
                                                       data: {
                                                            configureGA4: true,
                                                       },
                                                  })
                                             );
                                        } else {
                                             toastError(response);
                                        }
                                   });
                              }}
                              videoLink={SEND_US_DATA_NOTIFICATION_VIDEO.videoLink}
                              headline={'Configure GA4'}
                         />
                    )}
                    {showVideo.FBAds && (
                         <VideoPopupSetupGuide
                              videoRef={videoRef}
                              show={showVideo.FBAds}
                              onCancel={() => {
                                   dispatch(setShowVideo({ FBAds: false }));
                              }}
                              onSuccess={() => {
                                   const newData = {
                                        accountId: activeAccountId,
                                        step: 'destination',
                                        data: {
                                             configureFacebookAds: true,
                                        },
                                   };
                                   callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                                        if (response.status === 200) {
                                             dispatch(
                                                  setChildSetupGuideDataCompleted({
                                                       key: 'destination',
                                                       data: {
                                                            configureFacebookAds: true,
                                                       },
                                                  })
                                             );
                                        } else {
                                             toastError(response);
                                        }
                                   });
                              }}
                              videoLink={SEND_US_DATA_NOTIFICATION_VIDEO.videoLink}
                              headline={'Configure Facebook Ads'}
                         />
                    )}
                    {showVideo.GGAds && (
                         <VideoPopupSetupGuide
                              videoRef={videoRef}
                              show={showVideo.GGAds}
                              onCancel={() => {
                                   dispatch(setShowVideo({ GGAds: false }));
                              }}
                              onSuccess={() => {
                                   const newData = {
                                        accountId: activeAccountId,
                                        step: 'destination',
                                        data: {
                                             configureGoogleAds: true,
                                        },
                                   };
                                   callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                                        if (response.status === 200) {
                                             dispatch(
                                                  setChildSetupGuideDataCompleted({
                                                       key: 'destination',
                                                       data: {
                                                            configureGoogleAds: true,
                                                       },
                                                  })
                                             );
                                        } else {
                                             toastError(response);
                                        }
                                   });
                              }}
                              videoLink={SEND_US_DATA_NOTIFICATION_VIDEO.videoLink}
                              headline={'Configure Google Ads'}
                         />
                    )}
                    {showVideo.LinkedinAds && (
                         <VideoPopupSetupGuide
                              videoRef={videoRef}
                              show={showVideo.LinkedinAds}
                              onCancel={() => {
                                   dispatch(setShowVideo({ LinkedinAds: false }));
                              }}
                              onSuccess={() => {
                                   const newData = {
                                        accountId: activeAccountId,
                                        step: 'destination',
                                        data: {
                                             configureLinkedinAds: true,
                                        },
                                   };
                                   callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                                        if (response.status === 200) {
                                             dispatch(
                                                  setChildSetupGuideDataCompleted({
                                                       key: 'destination',
                                                       data: {
                                                            configureLinkedinAds: true,
                                                       },
                                                  })
                                             );
                                        } else {
                                             toastError(response);
                                        }
                                   });
                              }}
                              videoLink={SEND_US_DATA_NOTIFICATION_VIDEO.videoLink}
                              headline={'Configure Linkedin Ads'}
                         />
                    )}
                    {showVideo.MicrosoftAds && (
                         <VideoPopupSetupGuide
                              videoRef={videoRef}
                              show={showVideo.MicrosoftAds}
                              onCancel={() => {
                                   dispatch(setShowVideo({ MicrosoftAds: false }));
                              }}
                              onSuccess={() => {
                                   const newData = {
                                        accountId: activeAccountId,
                                        step: 'destination',
                                        data: {
                                             configureMicrosoftAds: true,
                                        },
                                   };
                                   callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                                        if (response.status === 200) {
                                             dispatch(
                                                  setChildSetupGuideDataCompleted({
                                                       key: 'destination',
                                                       data: {
                                                            configureMicrosoftAds: true,
                                                       },
                                                  })
                                             );
                                        } else {
                                             toastError(response);
                                        }
                                   });
                              }}
                              videoLink={SEND_US_DATA_NOTIFICATION_VIDEO.videoLink}
                              headline={'Configure Microsoft Ads'}
                         />
                    )}
               </div>
          </>
     );
};

export default SetupGuidePopup;

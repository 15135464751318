export const VARIABLE_MAIN_OPERATOR = [
     { value: 'exclude', label: 'Exclude' },
     { value: 'include', label: 'Include' },
];

export const VARIABLE_CONDITION_FOCUS = [
     { value: 'property', label: 'Property' },
     { value: 'metric', label: 'Metric' },
     { value: 'web-conversion-event', label: 'Web Conversion Event' },
     { value: 'ecommerce-event', label: 'Ecommerce Event' },
     { value: 'relationship', label: 'Relationship' },
     { value: 'other-event', label: 'Other Event' },
];

export const VARIABLE_SEGMENT_OBJECT = [
     { value: 'event', label: 'Event' },
     { value: 'session', label: 'Session' },
     { value: 'user', label: 'User' },
     { value: 'people', label: 'People' },
     { value: 'revealed-company', label: 'Revealed Company' },
];

export const VARIABLE_RELATIONSHIP_SEGMENT = [
     { value: 'event', label: 'Event' },
     { value: 'session', label: 'Session' },
     { value: 'user', label: 'User' },
     { value: 'people', label: 'People' },
     { value: 'revealed-company', label: 'Revealed Company' },
];

export const SEGMENT_OBJECT = {
     EVENT: 'event',
     SESSION: 'session',
     USER: 'user',
     PEOPLE: 'people',
     COMPANY: 'revealed-company',
};

export const CONDITION_FOCUS = {
     PROPERTY: 'property',
     METRIC: 'metric',
     WEB_CONVERSION_EVENT: 'web-conversion-event',
     ECOMMERCE_EVENT: 'ecommerce-event',
     RELATION_SHIP: 'relationship',
     OTHER_EVENT: 'other-event',
};

export const VARIABLE_CONDITION_FOCUS_OPTION = {
     [CONDITION_FOCUS.METRIC]: ['conversionNameMetrics'],
     [CONDITION_FOCUS.WEB_CONVERSION_EVENT]: ['conversionName'],
     [CONDITION_FOCUS.ECOMMERCE_EVENT]: ['eCommerceName'],
     [CONDITION_FOCUS.OTHER_EVENT]: ['eventName', 'reactSelectVariableOptions'],
};

export const SEGMENT_BOOLEAN_FIELDS = ['isConversion', 'adStorage', 'analyticsStorage', 'engaged', 'isTargetAccount'];

export const VARIABLE_CONDITION_PROPERTY_SEGMENT = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'does not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
     { value: 'exav', label: 'exists with any value' },
];

export const VARIABLE_CONDITION_MAIN_PROPERTY_SEGMENT = [
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
     { value: 'gt', label: 'greater than' },
     { value: 'lt', label: 'less than' },
     { value: 'gtoet', label: 'greater than or equal to' },
     { value: 'ltoet', label: 'less than or equal to' },
];

export const VARIABLE_CONDITION_REVEALED_DATE_SEGMENT = [
     { value: 'on', label: 'Is on' },
     { value: 'bf', label: 'Is before' },
     { value: 'af', label: 'Is after' },
     { value: 'prenod', label: 'is in some previous number of days' },
];

export const VARIABLE_CONDITION_RELATIONSHIP_SEGMENT = [
     { value: 'gt', label: 'more than' },
     { value: 'lt', label: 'less than' },
     { value: 'eq', label: 'equals to' },
     { value: 'gtoet', label: 'greater than or equal to' },
     { value: 'ltoet', label: 'less than or equal to' },
     { value: 'exav', label: 'exists' },
     { value: 'nex', label: 'does not exist' },
];

export const VARIABLE_CONDITION_METRIC_SEGMENT = [
     // { value: 'ct', label: 'contains' },
     // { value: 'nct', label: 'does not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
     { value: 'gt', label: 'greater than' },
     { value: 'lt', label: 'less than' },
     { value: 'gtoet', label: 'greater than or equal to' },
     { value: 'ltoet', label: 'less than or equal to' },
];

export const VARIABLE_CONDITION_METRIC_SEGMENT_CONTAIN = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'does not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
     { value: 'gt', label: 'greater than' },
     { value: 'lt', label: 'less than' },
     { value: 'gtoet', label: 'greater than or equal to' },
     { value: 'ltoet', label: 'less than or equal to' },
];

export const SEGMENT_CONDITION_TYPE = {
     EVENT_COUNT: 'event-count',
     EVENT_STATUS: 'event-status',
     HAS_PROPERTY: 'has-property',
     EVENT: 'event',
     VARIABLE: 'variable',
};

export const VARIABLE_TYPE_WEB_CONVERSION_SEGMENT = [
     { value: SEGMENT_CONDITION_TYPE.EVENT_COUNT, label: 'Event count is' },
     { value: SEGMENT_CONDITION_TYPE.EVENT_STATUS, label: 'Event status' },
     { value: SEGMENT_CONDITION_TYPE.HAS_PROPERTY, label: 'Has property' },
];

export const VARIABLE_CONDITION_WEB_CONVERSION_SEGMENT = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'does not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
     { value: 'gt', label: 'greater than' },
     { value: 'lt', label: 'less than' },
     { value: 'gtoet', label: 'greater than or equal to' },
     { value: 'exav', label: 'exists with any value' },
     { value: 'ltoet', label: 'less than or equal to' },
];

export const VARIABLE_DATE_RANGE_FILTER_TYPE_SEGMENT = [
     { value: 'lifetime-of-the-object', label: 'Lifetime of the object' },
     { value: 'previous-period', label: 'Previous period' },
     { value: 'specific-date-range', label: 'Specific date range' },
];

export const VARIABLE_TYPE_OTHER_EVENT_SEGMENT = [
     { value: 'event', label: 'Event' },
     { value: 'variable', label: 'Variable' },
];

export const VARIABLE_CONDITION_OTHER_EVENT_SEGMENT = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'does not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
     { value: 'exav', label: 'exists' },
     { value: 'nex', label: 'does not exist' },
];

export const VARIABLE_VALUE_SUPPORT_DATA = [
     { value: 'true', label: 'True' },
     { value: 'false', label: 'False' },
];

export const VARIABLE_CONDITION_DATE = [
     { value: 'on', label: 'on' },
     { value: 'bf', label: 'before' },
     { value: 'af', label: 'after' },
];

export const VARIABLE_LIST_MONTH = [
     { value: 'January', label: 'January' },
     { value: 'February', label: 'February' },
     { value: 'March', label: 'March' },
     { value: 'April', label: 'April' },
     { value: 'May', label: 'May' },
     { value: 'June', label: 'June' },
     { value: 'July', label: 'July' },
     { value: 'August', label: 'August' },
     { value: 'September', label: 'September' },
     { value: 'October', label: 'October' },
     { value: 'November', label: 'November' },
     { value: 'December', label: 'December' },
]

export const VARIABLE_LIST_DAY_OF_WEEK = [
     { value: 'Sunday', label: 'Sunday' },
     { value: 'Monday', label: 'Monday' },
     { value: 'Tuesday', label: 'Tuesday' },
     { value: 'Wednesday', label: 'Wednesday' },
     { value: 'Thursday', label: 'Thursday' },
     { value: 'Friday', label: 'Friday' },
     { value: 'Saturday', label: 'Saturday' },
]

export const VARIABLE_LIST_HOUR = [
     { value: '1 AM', label: '1 AM' },
     { value: '2 AM', label: '2 AM' },
     { value: '3 AM', label: '3 AM' },
     { value: '4 AM', label: '4 AM' },
     { value: '5 AM', label: '5 AM' },
     { value: '6 AM', label: '6 AM' },
     { value: '7 AM', label: '7 AM' },
     { value: '8 AM', label: '8 AM' },
     { value: '9 AM', label: '9 AM' },
     { value: '10 AM', label: '10 AM' },
     { value: '11 AM', label: '11 AM' },
     { value: '12 AM', label: '12 AM'},
     { value: '1 PM', label: '1 PM' },
     { value: '2 PM', label: '2 PM' },
     { value: '3 PM', label: '3 PM' },
     { value: '4 PM', label: '4 PM' },
     { value: '5 PM', label: '5 PM' },
     { value: '6 PM', label: '6 PM' },
     { value: '7 PM', label: '7 PM' },
     { value: '8 PM', label: '8 PM' },
     { value: '9 PM', label: '9 PM' },
     { value: '10 PM', label: '10 PM' },
     { value: '11 PM', label: '11 PM' },
     { value: '12 PM', label: '12 PM' },
]

export const VARIABLE_LIST_QUARTER_OF_THE_YEAR = [
     { value: 'Q1', label: 'Q1' },
     { value: 'Q2', label: 'Q2' },
     { value: 'Q3', label: 'Q3' },
     { value: 'Q4', label: 'Q4' },
]

export const VARIABLE_VALUE_CONVERSION_TYPE = [
     { value: 'secondary', label: 'Secondary' },
     { value: 'primary', label: 'Primary' },
     { value: 'None', label: 'None' },
]

export const VARIABLE_VALUE_EMAIL_TYPE = [
     { value: 'business', label: 'Business' },
     { value: 'personal', label: 'Personal' },
     { value: 'None', label: 'None' },
];

export const SECTION_OPTION_SEGMENT = {
     IDENTIFIER_RELATIONSHIPS: 'Identifiers & Relationships',
     DATE_ACCOUNT_TIMEZONE: 'Date',
     CONVERSIONS: 'Conversions',
     CONVERSION_SOURCE_INFOR: 'Conversion Source Infor',
     ECOMMERCE_EVENT_DETAILS: 'eCommerce Event Details',
     ECOMMERCE_ITEMS: 'eCommerce Items',
     ECOMMERCE_SOURCE_INFOR: 'eCommerce Source Infor',
     GEOGRAPHY: 'Geography',
     CONSENT: 'Consent',
     DEVICE: 'Device',
     BROWSER: 'Browser',
     OS: 'OS',
     SCREEN: 'Screen',
     ENGAGEMENT: 'Engagement',
     FORM_ACTIVITY: 'Form Activity',
     SOURCE: 'Source',
     PAGE_ACTIVITY: 'Page Activity',
     PEOPLE: 'People',
     ECOMMERCE: 'eCommerce',
     TRIGGER: 'Triggers',
     COMPANY_DETAILS: 'Company Details',
     LIFETIME_SOURCE_SUMMARY: 'Lifetime Source Summary',
     USER_SOURCE: 'userSource',
     CHANNEL: 'Channel',
     CLICK_ID: 'clickID',
     REFERRER: 'Referrer',
     UTM: 'UTM',
     CREATED_USER_SOURCE: 'Created userSource',
     ENGAGEMENT_ACTIVITY: 'Engagement & Activity',
     PERSONAL_DATA: 'Personal Data',
     PERSON_CREATED_SOURCE_INFOR: 'Person Created Source Infor',
     CUSTOM_PROPERTIES: 'Custom Properties',
     LOCATION: 'Location',
     SIZE: 'Size',
     INDUSTRY: 'Industry',
     REVEALED_SOURCE_SUMMARY: 'Revealed Source Summary',
     TRACKING_LEVEL: 'Tracking Level',
     EVENT_DATA: 'Event Data',
     GENERAL_INFORMATION: 'General Information',
     CREATED_SECTION: 'Created Session',
     CREATED_SOURCE: 'Created Source',
     CREATED_SOURCE_REFERRER: 'Created Source Referrer',
     CREATED_LOCATION: 'Created Location',
     CREATED_UTM: 'Created UTM',
     CREATED_CLICK_IDS: 'Created ClickIDs',
     SESSIONID: 'Session',
     TARGET_ACCOUNT: 'Target Account',
};

export const SEGMENT_OBJECT_DATA = {
     [SEGMENT_OBJECT.EVENT]: {
          property: [
               { value: 'eventName', label: 'Event Name', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'eventLocation', label: 'Event Location', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'creationDate', label: 'Creation Date', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'isConversion', label: 'Is Conversion?', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'conversionType', label: 'Conversion Type', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'userId', label: 'User ID', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'sessionId', label: 'Session ID', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'personId', label: 'Person ID', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'consentMethod', label: 'Consent Method', section: SECTION_OPTION_SEGMENT.TRACKING_LEVEL  },
               { value: 'trackingLevel', label: 'Tracking Level', section: SECTION_OPTION_SEGMENT.TRACKING_LEVEL  },
               { value: 'consentRegionalRule', label: 'Consent Regional Rule', section: SECTION_OPTION_SEGMENT.TRACKING_LEVEL  },
               { value: 'adStorage', label: 'ad_storage', section: SECTION_OPTION_SEGMENT.TRACKING_LEVEL  },
               { value: 'analyticsStorage', label: 'analytics_storage', section: SECTION_OPTION_SEGMENT.TRACKING_LEVEL  },
               { value: 'identifyStrength', label: 'Identify Strength', section: SECTION_OPTION_SEGMENT.TRACKING_LEVEL   }, 

          ],
          metric: [{ value: 'eventCount', label: 'Events', section: SECTION_OPTION_SEGMENT.EVENT_DATA  }],
     },
     [SEGMENT_OBJECT.SESSION]: {
          property: [
               { value: 'landingPage', label: 'Landing Page', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY },
               { value: 'exitPage', label: 'Exit Page', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY },
               { value: 'engaged', label: 'Engaged', section: SECTION_OPTION_SEGMENT.ENGAGEMENT },
               { value: 'creationDate', label: 'Creation Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'sessionNumber', label: 'Session Number', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },

               // { value: 'sessionID', label: 'Session ID', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
               { value: 'userID', label: 'User ID', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
               // { value: 'date', label: 'Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'hour', label: 'Hour', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'dayOfWeek', label: 'Day of Week', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'month', label: 'Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               { value: 'yearMonth', label: 'Year Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               { value: 'quarter', label: 'Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               { value: 'yearQuarter', label: 'Year Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               // { value: 'regionalRule', label: 'Regional Rule' }, 
               { value: 'consentMethod', label: 'Consent Method', section: SECTION_OPTION_SEGMENT.CONSENT },
               // { value: 'trackingLevel', label: 'Tracking Level' }, 
               { value: 'identifyStrength', label: 'Identify Strength', section: SECTION_OPTION_SEGMENT.CONSENT },
               { value: 'country', label: 'Country', section: SECTION_OPTION_SEGMENT.GEOGRAPHY },
               { value: 'continent', label: 'Continent', section: SECTION_OPTION_SEGMENT.GEOGRAPHY },
               // { value: 'region', label: 'Region' }, 
               { value: 'city', label: 'City', section: SECTION_OPTION_SEGMENT.GEOGRAPHY }, 
               // { value: 'postalCode', label: 'Postal Code' }, 
               { value: 'dmaCode', label: 'DMA Code', section: SECTION_OPTION_SEGMENT.GEOGRAPHY  }, 
               { value: 'dmaName', label: 'DMA Name', section: SECTION_OPTION_SEGMENT.GEOGRAPHY  }, 
               { value: 'deviceType', label: 'Device Type', section: SECTION_OPTION_SEGMENT.DEVICE },
               { value: 'deviceFamily', label: 'Device Family', section: SECTION_OPTION_SEGMENT.DEVICE }, 
               { value: 'deviceManufacturer', label: 'Device Manufacturer', section: SECTION_OPTION_SEGMENT.DEVICE }, 
               { value: 'browserFamily', label: 'Browser Family', section: SECTION_OPTION_SEGMENT.BROWSER },
               { value: 'browserVersion', label: 'Browser Version', section: SECTION_OPTION_SEGMENT.BROWSER  }, 
               { value: 'browserDetailedVersion', label: 'Browser Detailed Version', section: SECTION_OPTION_SEGMENT.BROWSER }, 
               { value: 'osFamily', label: 'OS Family', section: SECTION_OPTION_SEGMENT.OS }, 
               { value: 'osVersion', label: 'OS Version', section: SECTION_OPTION_SEGMENT.OS }, 
               { value: 'osDetailedVersion', label: 'OS Detailed Version', section: SECTION_OPTION_SEGMENT.OS }, 
               { value: 'screenColors', label: 'Screen Colors', section: SECTION_OPTION_SEGMENT.SCREEN }, 
               { value: 'screenSize', label: 'Screen Size', section: SECTION_OPTION_SEGMENT.SCREEN }, 
               { value: 'lastEventDate', label: 'Last Event Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               { value: 'lastConversionDate', label: 'Last Conversion Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               // { value: 'conversions.conversionID', label: 'Conversion Events (Conversion ID)' }, 

               { value: 'landingPageURL', label: 'Landing Page URL', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY },
               { value: 'landingPageHostname', label: 'Landing Page Hostname', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY },
               { value: 'landingPagePath', label: 'Landing Page Path', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY },
               { value: 'exitPageURL', label: 'Exit Page URL', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY },
               { value: 'exitPageHostname', label: 'Exit Page Hostname', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY },
               { value: 'exitPagePath', label: 'Exit Page Path', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY },

               // { value: 'source.source', label: 'Source' }, // Unnecessary
               { value: 'userSource', label: 'User Source', section: SECTION_OPTION_SEGMENT.SOURCE },
               { value: 'channel', label: 'Channel', section: SECTION_OPTION_SEGMENT.CHANNEL },
               { value: 'source.referrerHostname', label: 'Referrer Hostname' , section: SECTION_OPTION_SEGMENT.REFERRER, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.referrerOriginPath', label: 'Referrer Origin Path' , section: SECTION_OPTION_SEGMENT.REFERRER, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.referrerPath', label: 'Referrer Path' , section: SECTION_OPTION_SEGMENT.REFERRER, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.utm_medium', label: 'utm_medium' , section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.utm_source', label: 'utm_source' , section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.utm_campaign', label: 'utm_campaign' , section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.utm_term', label: 'utm_term' , section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.utm_content', label: 'utm_content' , section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.gclid', label: 'Click IDs (gclid)' , section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.fbclid', label: 'Click IDs (fbclid)' , section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.fbp', label: 'Click IDs (fbp)' , section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.fbc', label: 'Click IDs (fbc)' , section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.msclkid', label: 'Click IDs (msclkid)' , section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.li_fat_id', label: 'Click IDs (li_fat_id)' , section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.ttclid', label: 'Click IDs (ttclid)' , section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.dclid', label: 'Click IDs (dclid)' , section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.srsltid', label: 'Click IDs (srsltid)' , section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source._ga', label: 'Click IDs (_ga)' , section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.calltrk_user', label: 'Click IDs (calltrk_user)' , section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.SOURCE},

               { value: 'forms.assignedID', label: 'Assigned ID', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.locationOriginPathname', label: 'Form Location', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.locationHostnamePath', label: 'Form Hostname Path', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.lastSubmissionDate', label: 'Last Submission Date', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },

               { value: 'personCreated', label: 'Person ID', section: SECTION_OPTION_SEGMENT.PEOPLE },

               { value: 'isTargetAccount', label: 'Is Target Account', section: SECTION_OPTION_SEGMENT.TARGET_ACCOUNT },
               { value: 'targetAccountDomain', label: 'Target Account Domain', section: SECTION_OPTION_SEGMENT.TARGET_ACCOUNT },
          ],
          metric: [
               { value: 'eventCount', label: 'Events', section: SECTION_OPTION_SEGMENT.ENGAGEMENT },
               { value: 'engagementScore', label: 'Engagement Score', section: SECTION_OPTION_SEGMENT.ENGAGEMENT },
               { value: 'pageViewCount', label: 'Pageviews', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY },
               { value: 'sessionDuration', label: 'Session Duration', section: SECTION_OPTION_SEGMENT.ENGAGEMENT },
               { value: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page', section: SECTION_OPTION_SEGMENT.ENGAGEMENT },
               { value: 'personCreatedCount', label: 'Person Created', section: SECTION_OPTION_SEGMENT.PEOPLE },
               { value: 'conversionCount', label: 'All Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'primaryConversionCount', label: 'Primary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'secondaryConversionCount', label: 'Secondary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },

               { value: 'visibleTime', label: 'Total Visible Time', section: SECTION_OPTION_SEGMENT.ENGAGEMENT },
               { value: 'conversionValue', label: 'Conversion Value', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'primaryConvValue', label: 'Primary Conversion Value', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'secondaryConvValue', label: 'Secondary Conversion Value', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               
               { value: 'ecomEventValue.addToCart', label: 'Add to Cart Value', section: SECTION_OPTION_SEGMENT.ECOMMERCE },
               { value: 'ecomEventValue.removeFromCart', label: 'Remove from Cart Value', section: SECTION_OPTION_SEGMENT.ECOMMERCE },
               { value: 'ecomEventValue.netCart', label: 'Net Cart Value', section: SECTION_OPTION_SEGMENT.ECOMMERCE },
               { value: 'ecomEventValue.beginCheckoutUnique', label: 'Begin Checkout Unique', section: SECTION_OPTION_SEGMENT.ECOMMERCE },
               { value: 'ecomEventRevenue.purchase', label: 'Purchase Revenue', section: SECTION_OPTION_SEGMENT.ECOMMERCE },

               { value: 'forms.impressionCount', label: 'Impression Count', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.visibleCount', label: 'Form Visible Count', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.startCount', label: 'Form Start Count', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.errorCount', label: 'Form Error Count', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.submissions', label: 'Submissions Count', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               // { value: 'forms.revealedPeopleCount', label: 'Revealed People Count', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               // { value: 'createdPersonCount', label: 'Created Person Count' }, // Unnecessary
          ],
     },
     [SEGMENT_OBJECT.USER]: {
          property: [
               { value: 'userSourceFirst', label: 'User Source - First' , section: SECTION_OPTION_SEGMENT.USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY},
               { value: 'userSourcePath', label: 'User Source - Path' , section: SECTION_OPTION_SEGMENT.USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY},
               { value: 'noneDirectSourcePath', label: 'Non-Direct User Source - Path' , section: SECTION_OPTION_SEGMENT.USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY},
               { value: 'userSourceLast', label: 'User Source - Last' , section: SECTION_OPTION_SEGMENT.USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY},
               { value: 'channelFirst', label: 'Channel - First', section: SECTION_OPTION_SEGMENT.CHANNEL },
               { value: 'channelLast', label: 'Channel - Last', section: SECTION_OPTION_SEGMENT.CHANNEL },
               { value: 'channelPath', label: 'Channel - Path', section: SECTION_OPTION_SEGMENT.CHANNEL },

               { value: 'creationDate', label: 'Creation Date' , section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE},
               { value: 'createdHour', label: 'Creation Hour', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'createdDayOfWeek', label: 'Creation Day of Week', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'createdMonth', label: 'Creation Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               { value: 'createdYearMonth', label: 'Creation Year Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               { value: 'createdQuarter', label: 'Creation Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               { value: 'createdYearQuarter', label: 'Creation Year Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               { value: 'lastConversionDate', label: 'Last Conversion Date' , section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE},
               { value: 'lastEventDate', label: 'Last Event Date' , section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE},

               { value: 'userID', label: 'User ID', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
               { value: 'personID', label: 'Person ID', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },

               // { value: 'regionalRule', label: 'Regional Rule' }, 
               { value: 'consentMethod', label: 'Consent Method', section: SECTION_OPTION_SEGMENT.CONSENT }, 
               // { value: 'trackingLevel', label: 'Tracking Level' }, 
               // { value: 'identifyStrength', label: 'Identify Strength' }, 
               { value: 'country', label: 'Country', section: SECTION_OPTION_SEGMENT.GEOGRAPHY }, 
               { value: 'continent', label: 'Continent', section: SECTION_OPTION_SEGMENT.GEOGRAPHY }, 
               // { value: 'region', label: 'Region' }, 
               { value: 'city', label: 'City', section: SECTION_OPTION_SEGMENT.GEOGRAPHY }, 
               // { value: 'postalCode', label: 'Postal Code' }, 
               { value: 'dmaCode', label: 'DMA Code', section: SECTION_OPTION_SEGMENT.GEOGRAPHY }, 
               { value: 'dmaName', label: 'DMA Name', section: SECTION_OPTION_SEGMENT.GEOGRAPHY }, 

               { value: 'deviceType', label: 'Device Type', section: SECTION_OPTION_SEGMENT.DEVICE }, 
               { value: 'deviceFamily', label: 'Device Family', section: SECTION_OPTION_SEGMENT.DEVICE }, 
               { value: 'deviceManufacturer', label: 'Device Manufacturer', section: SECTION_OPTION_SEGMENT.DEVICE }, 

               { value: 'browserFamily', label: 'Browser Family', section: SECTION_OPTION_SEGMENT.BROWSER }, 
               { value: 'browserVersion', label: 'Browser Version', section: SECTION_OPTION_SEGMENT.BROWSER }, 
               { value: 'browserDetailedVersion', label: 'Browser Detailed Version', section: SECTION_OPTION_SEGMENT.BROWSER },

               { value: 'osFamily', label: 'OS Family', section: SECTION_OPTION_SEGMENT.OS },
               { value: 'osVersion', label: 'OS Version', section: SECTION_OPTION_SEGMENT.OS },
               { value: 'osDetailedVersion', label: 'OS Detailed Version', section: SECTION_OPTION_SEGMENT.OS },

               { value: 'screenColors', label: 'Screen Colors', section: SECTION_OPTION_SEGMENT.SCREEN }, 
               { value: 'screenSize', label: 'Screen Size', section: SECTION_OPTION_SEGMENT.SCREEN }, 
               // { value: 'isEngaged', label: 'Engaged', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY }, 

               // { value: 'sourceFirstND', label: 'Source (first non-direct)' }, 
               // { value: 'sourceLastND', label: 'Source (last non-direct)' }, 
               // { value: 'sources.sessionID', label: 'Sources <br>(Source Session ID)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               // { value: 'sources.timestamp', label: 'Sources (Timestamp)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               // { value: 'sources.source', label: 'Source' },

               { value: 'sessionIDFirst', label: 'Session ID (first)', section: SECTION_OPTION_SEGMENT.SESSIONID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sessionIDFirstND', label: 'Session ID <br>(first non-direct)', section: SECTION_OPTION_SEGMENT.SESSIONID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sessionIDLast', label: 'Session ID (last)', section: SECTION_OPTION_SEGMENT.SESSIONID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sessionIDLastND', label: 'Session ID <br>(last non-direct)', section: SECTION_OPTION_SEGMENT.SESSIONID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'lifetimeSessionIDPath', label: 'Session ID (path)', section: SECTION_OPTION_SEGMENT.SESSIONID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY }, 

               { value: 'sources.referrerHostname', label: 'Referrer Hostname', section: SECTION_OPTION_SEGMENT.REFERRER, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.referrerOriginPath', label: 'Referrer Origin Path', section: SECTION_OPTION_SEGMENT.REFERRER, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.referrerPath', label: 'Referrer Path', section: SECTION_OPTION_SEGMENT.REFERRER, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },

               { value: 'sources.utm_medium', label: 'utm_medium', section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.utm_source', label: 'utm_source', section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.utm_campaign', label: 'utm_campaign', section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.utm_term', label: 'utm_term', section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.utm_content', label: 'utm_content', section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
              
               { value: 'sources.gclid', label: 'Click IDs (gclid)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.fbclid', label: 'Click IDs (fbclid)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.fbp', label: 'Click IDs (fbp)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.fbc', label: 'Click IDs (fbc)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.msclkid', label: 'Click IDs (msclkid)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.li_fat_id', label: 'Click IDs (li_fat_id)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.ttclid', label: 'Click IDs (ttclid)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.dclid', label: 'Click IDs (dclid)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.srsltid', label: 'Click IDs (srsltid)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources._ga', label: 'Click IDs (_ga)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.calltrk_user', label: 'Click IDs (calltrk_user)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },

               { value: 'isTargetAccount', label: 'Is Target Account', section: SECTION_OPTION_SEGMENT.TARGET_ACCOUNT },
               { value: 'targetAccountDomain', label: 'Target Account Domain', section: SECTION_OPTION_SEGMENT.TARGET_ACCOUNT },
          ],
          metric: [
               { value: 'eventCount', label: 'Events', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'sessionCount', label: 'Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'avgSessionDuration', label: 'Avg Session Duration', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'engagedSessionCount', label: 'Engaged Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'engagementRate', label: 'Engagement Rate', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'engagementScore', label: 'Lifetime Engagement Score', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'bouncedSessionCount', label: 'Bounced Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'bounceRate', label: 'Bounce Rate', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'pageViewCount', label: 'Pageviews', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'avgPageViewsPerSession', label: 'Avg Pageviews / Session', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'sourcePathCount', label: 'Source Path Count', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY},
               { value: 'noneDirectSourcePathCount', label: 'Non-Direct Source Path Count', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY},
               { value: 'conversionCount', label: 'All Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS},
               { value: 'primaryConversionCount', label: 'Primary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS},
               { value: 'secondaryConversionCount', label: 'Secondary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS},
               { value: 'conversionRateAll', label: 'Conv Rate <br>(All Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS},
               { value: 'conversionRatePrimary', label: 'Conv Rate <br>(Primary Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS},
               { value: 'conversionRateSecondary', label: 'Conv Rate <br>(Secondary Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS},

               { value: 'revealedCompanyCount', label: 'Revealed Company Count', section: SECTION_OPTION_SEGMENT.COMPANY_DETAILS },
               // { value: 'engagementScore.10Days', label: 'Previous 10 Days\' Engagement Score' },
               // { value: 'engagementScore.30Days', label: 'Previous 30 Days\' Engagement Score' },
               { value: 'lifetimeVisibleTime', label: 'Total Visible Time <br>(Lifetime)', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'conversionValue', label: 'Conversion Value', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'primaryConvValue', label: 'Primary Conversion Value <br>(Lifetime)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'secondaryConvValue', label: 'Secondary Conversion Value <br>(Lifetime)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },

          ],
     },
     [SEGMENT_OBJECT.COMPANY]: {
          property: [
               { value: 'companyName', label: 'Company Name', section: SECTION_OPTION_SEGMENT.GENERAL_INFORMATION },
               { value: 'domain', label: 'Domain', section: SECTION_OPTION_SEGMENT.GENERAL_INFORMATION },

               { value: 'revenue', label: 'Revenue', section: SECTION_OPTION_SEGMENT.SIZE },
               { value: 'employees', label: 'Total Employees', section: SECTION_OPTION_SEGMENT.SIZE },

               { value: 'primaryIndustry', label: 'Primary Industry', section: SECTION_OPTION_SEGMENT.INDUSTRY },
               { value: 'industries', label: 'Products & Services Tags', section: SECTION_OPTION_SEGMENT.INDUSTRY },

               { value: 'companyCountry', label: 'Country', section: SECTION_OPTION_SEGMENT.LOCATION },
               { value: 'companyState', label: 'State', section: SECTION_OPTION_SEGMENT.LOCATION },

               { value: 'createdDate', label: 'Creation date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'latestUpdateDate', label: 'Latest update date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'lastConversionDate', label: 'Last Conversion Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },

               { value: 'userSourceFirst', label: 'User Source - First', section: SECTION_OPTION_SEGMENT.USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.REVEALED_SOURCE_SUMMARY },
               { value: 'userSourcePath', label: 'User Source - Path', section: SECTION_OPTION_SEGMENT.USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.REVEALED_SOURCE_SUMMARY },
               { value: 'noneDirectSourcePath', label: 'Non-Direct User Source - Path', section: SECTION_OPTION_SEGMENT.USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.REVEALED_SOURCE_SUMMARY },
               { value: 'userSourceLast', label: 'User Source - Last', section: SECTION_OPTION_SEGMENT.USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.REVEALED_SOURCE_SUMMARY },

               { value: 'channelFirst', label: 'Channel - First', section: SECTION_OPTION_SEGMENT.CHANNEL },
               { value: 'channelLast', label: 'Channel - Last', section: SECTION_OPTION_SEGMENT.CHANNEL },
               { value: 'channelPath', label: 'Channel - Path', section: SECTION_OPTION_SEGMENT.CHANNEL },

               { value: 'isTargetAccount', label: 'Is Target Account', section: SECTION_OPTION_SEGMENT.TARGET_ACCOUNT },
               // { value: 'targetAccountDomain', label: 'Target Account Domain', section: SECTION_OPTION_SEGMENT.TARGET_ACCOUNT },

               // { value: 'companyID', label: 'Company ID' },
               // { value: 'revealedDate', label: 'Revealed Date' },
               // { value: 'lastActiveDate', label: 'Last Active Date' },
               // { value: 'revealedHour', label: 'Revealed Hour' },
               // { value: 'revealedDayOfWeek', label: 'Revealed Day of Week' },
               // { value: 'revealedMonth', label: 'Revealed Month' },
               // { value: 'revealedYearMonth', label: 'Revealed Year Month' },
               // { value: 'revealedQuarter', label: 'Revealed Quarter' },
               // { value: 'revealedYearQuarter', label: 'Revealed Year Quarter' },
               // { value: 'descriptionShort', label: 'Short Description' },
               // { value: 'description', label: 'Long Description' },
               // { value: 'yearFounded', label: 'Year Founded' },

               // { value: 'country', label: 'Country' }, 
               // { value: 'countryCode', label: 'Country Code' }, 
               // { value: 'region', label: 'Region' }, 
               // { value: 'regionCode', label: 'Region Code' }, 
               // { value: 'city', label: 'City' }, 
               // { value: 'postalCode', label: 'Postal Code' },
               // { value: 'timezoneName', label: 'Timezone Name' },
               // { value: 'alexaRange', label: 'Alexa Rank' },
               // { value: 'monthlyVisitors', label: 'Monthly Visitors' },
               // { value: 'businessType', label: 'Business Type' },
               // { value: 'stockExchange', label: 'Stock Exchange' },
               // { value: 'tickerSymbol', label: 'Ticker Symbol' },
               // { value: 'naicsCode', label: 'NAICS Code' },
               // { value: 'naicsSector', label: 'NAICS Sector' },
               // { value: 'naicsSubsector', label: 'NAICS Subsector' },
               // { value: 'naicsIndustryGroup', label: 'NAICS Industry Group' },
               // { value: 'naicsIndustry', label: 'NAICS Industry' },
               // { value: 'naicsDescription', label: 'NAICS Description' },
               // { value: 'sicCode', label: 'SIC Code' },
               // { value: 'sicIndustryGroup', label: 'SIC Industry Group' },
               // { value: 'sicIndustry', label: 'SIC Industry' },
               // { value: 'isEngaged', label: 'Engaged' },
               // { value: 'revealedSessionID', label: 'Revealed Session ID' },
               // { value: 'createdSourceFirst', label: 'Created Source (first)' },
               // { value: 'createdSourceFirstND', label: 'Created Source (first non-direct)' },
               // { value: 'createdSourceLast', label: 'Created Source (last)' },
               // { value: 'createdSourceLastND', label: 'Created Source (last non-direct)' },
               // { value: 'createdSourcePath', label: 'Created Source (path)' },
               // { value: 'createdSourcePathND', label: 'Created Source (path non-direct)' },
               
               // { value: 'createdSources.sessionID', label: 'Created Sources (Source Session ID)' },
               // { value: 'createdSources.source', label: 'Created Sources (Source)' },
               // { value: 'createdSources.timestamp', label: 'Created Sources (Timestamp)' },
               // { value: 'createdSources.referrerHostname', label: 'Created Sources (Referrer Hostname)' },
               // { value: 'createdSources.referrerOriginPath', label: 'Created Sources (Referrer Origin Path)' },
               // { value: 'createdSources.referrerPath', label: 'Created Sources (Referrer Path)' },
               // { value: 'createdSources.utm_medium', label: 'Created Sources (utm_medium)' },
               // { value: 'createdSources.utm_source', label: 'Created Sources (utm_source)' },
               // { value: 'createdSources.utm_campaign', label: 'Created Sources (utm_campaign)' },
               // { value: 'createdSources.utm_term', label: 'Created Sources (utm_term)' },
               // { value: 'createdSources.utm_content', label: 'Created Sources (utm_content)' },
               // { value: 'createdSources.gclid', label: 'Created Sources Click IDs (gclid)' },
               // { value: 'createdSources.fbclid', label: 'Created Sources Click IDs (fbclid)' },
               // { value: 'createdSources.fbp', label: 'Created Sources Click IDs (fbp)' },
               // { value: 'createdSources.fbc', label: 'Created Sources Click IDs (fbc)' },
               // { value: 'createdSources.msclkid', label: 'Created Sources Click IDs (msclkid)' },
               // { value: 'createdSources.li_fat_id', label: 'Created Sources Click IDs (li_fat_id)' },
               // { value: 'createdSources.ttclid', label: 'Created Sources Click IDs (ttclid)' },
               // { value: 'createdSources.dclid', label: 'Created Sources Click IDs (dclid)' },
               // { value: 'createdSources.srsltid', label: 'Created Sources Click IDs (srsltid)' },
               // { value: 'createdSources._ga', label: 'Created Sources Click IDs (_ga)' },
               // { value: 'createdSources.calltrk_user', label: 'Created Sources Click IDs (calltrk_user)' },
               // { value: 'createdSources.weights.engagement', label: 'Created Sources Weights (Engagement)' },
               // { value: 'createdSources.weights.engagementND', label: 'Created Sources Weights (Engagement Non Direct)' },
               // { value: 'createdSources.weights.firstTouch', label: 'Created Sources Weights (First Touch)' },
               // { value: 'createdSources.weights.firstTouchND', label: 'Created Sources Weights (First Touch Non Direct)' },
               // { value: 'createdSources.weights.lastTouch', label: 'Created Sources Weights (Last Touch)' },
               // { value: 'createdSources.weights.lastTouchND', label: 'Created Sources Weights (Last Touch Non Direct)' },
               // { value: 'createdSources.weights.uShaped', label: 'Created Sources Weights (U-Shaped)' },
               // { value: 'createdSources.weights.uShapedND', label: 'Created Sources Weights (U-Shaped Non Direct)' },
               // { value: 'createdSources.weights.linear', label: 'Created Sources Weights (Linear)' },
               // { value: 'createdSources.weights.linearND', label: 'Created Sources Weights (Linear Non Direct)' },

               // { value: 'sourceFirstND', label: 'Source (first non-direct)' }, 
               // { value: 'sourceLastND', label: 'Source (last non-direct)' }, 
               // { value: 'sources.sessionID', label: 'Sources (Source Session ID)' },
               // { value: 'sources.timestamp', label: 'Sources (Timestamp)' },
               // { value: 'sources.source', label: 'Source' },
               // { value: 'sources.referrerHostname', label: 'Referrer Hostname' },
               // { value: 'sources.referrerOriginPath', label: 'Referrer Origin Path' },
               // { value: 'sources.referrerPath', label: 'Referrer Path' },
               // { value: 'sources.utm_medium', label: 'utm_medium' },
               // { value: 'sources.utm_source', label: 'utm_source' },
               // { value: 'sources.utm_campaign', label: 'utm_campaign' },
               // { value: 'sources.utm_term', label: 'utm_term' },
               // { value: 'sources.utm_content', label: 'utm_content' },
               // { value: 'sources.gclid', label: 'Click IDs (gclid)' },
               // { value: 'sources.fbclid', label: 'Click IDs (fbclid)' },
               // { value: 'sources.fbp', label: 'Click IDs (fbp)' },
               // { value: 'sources.fbc', label: 'Click IDs (fbc)' },
               // { value: 'sources.msclkid', label: 'Click IDs (msclkid)' },
               // { value: 'sources.li_fat_id', label: 'Click IDs (li_fat_id)' },
               // { value: 'sources.ttclid', label: 'Click IDs (ttclid)' },
               // { value: 'sources.dclid', label: 'Click IDs (dclid)' },
               // { value: 'sources.srsltid', label: 'Click IDs (srsltid)' },
               // { value: 'sources._ga', label: 'Click IDs (_ga)' },
               // { value: 'sources.calltrk_user', label: 'Click IDs (calltrk_user)' },
          ],
          metric: [
               { value: 'peopleCount', label: 'People', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'userCount', label: 'Users', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'sessionCount', label: 'Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'eventCount', label: 'Events', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'engagedSessionCount', label: 'Engaged Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'engagementScore', label: 'Lifetime Engagement Score', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'avgSessionDuration', label: 'Avg Session Duration', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'sourcePathCount', label: 'Source Path Count', section: SECTION_OPTION_SEGMENT.REVEALED_SOURCE_SUMMARY },
               { value: 'noneDirectSourcePathCount', label: 'Non-Direct Source Path Count', section: SECTION_OPTION_SEGMENT.REVEALED_SOURCE_SUMMARY },
               { value: 'conversionCount', label: 'All Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'primaryConversionCount', label: 'Primary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'secondaryConversionCount', label: 'Secondary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'conversionRateAll', label: 'Conv Rate (All Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'conversionRatePrimary', label: 'Conv Rate <br>(Primary Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'conversionRateSecondary', label: 'Conv Rate <br>(Secondary Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               
               // { value: 'employeeCountEstimate', label: 'Employee Exact Estimate' },
               // { value: 'engagementScore.10Days', label: 'Previous 10 Days\' Engagement Score' },
               // { value: 'engagementScore.30Days', label: 'Previous 30 Days\' Engagement Score' },
               // { value: 'revealedCompanyCount', label: 'Revealed Company Count' },
               // { value: 'sessionDuration', label: 'Session Duration' },
               // { value: 'pageViewCount', label: 'Pageview Count' },
               // { value: 'conversionValue', label: 'Conversion Value' },
               // { value: 'primaryConvValue', label: 'Primary Conversion Value (Lifetime)' },
               // { value: 'secondaryConvValue', label: 'Secondary Conversion Value (Lifetime)' },
          ],
     },
     [SEGMENT_OBJECT.PEOPLE]: {
          property: [
               { value: 'email', label: 'Email', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'domain', label: 'Email Domain', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'emailList', label: 'Email List', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'emailType', label: 'Email Type', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'externalId', label: 'External ID', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'name', label: 'Name', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'firstName', label: 'First Name', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'lastName', label: 'Last Name', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'address', label: 'Address', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'phone', label: 'Phone', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'phoneList', label: 'Phone List', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },

               { value: 'userSourceFirst', label: 'Lifetime User Source - First', section: SECTION_OPTION_SEGMENT.USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'userSourcePath', label: 'Lifetime User Source - Path', section: SECTION_OPTION_SEGMENT.USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'noneDirectSourcePath', label: 'Lifetime Non-Direct User Source - Path', section: SECTION_OPTION_SEGMENT.USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'userSourceLast', label: 'Lifetime User Source - Last', section: SECTION_OPTION_SEGMENT.USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },

               { value: 'channelFirst', label: 'Channel - First', section: SECTION_OPTION_SEGMENT.CHANNEL },
               { value: 'channelLast', label: 'Channel - Last', section: SECTION_OPTION_SEGMENT.CHANNEL },
               { value: 'channelPath', label: 'Channel - Path', section: SECTION_OPTION_SEGMENT.CHANNEL },

               { value: 'sessionIDFirst', label: 'Session ID (first)', section: SECTION_OPTION_SEGMENT.SESSIONID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sessionIDLast', label: 'Session ID (last)', section: SECTION_OPTION_SEGMENT.SESSIONID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'lifetimeSessionIDPath', label: 'Lifetime Session ID (path)', section: SECTION_OPTION_SEGMENT.SESSIONID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'lifetimeSessionIDPathND', label: 'Lifetime Session ID <br>(path non-direct)', section: SECTION_OPTION_SEGMENT.SESSIONID, sectionParent: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },

               { value: 'identifiedSessionIDFirst', label: 'Created Session ID (first)', section: SECTION_OPTION_SEGMENT.CREATED_SECTION, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSessionIDFirstND', label: 'Created Session ID <br>(first non-direct)', section: SECTION_OPTION_SEGMENT.CREATED_SECTION, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSessionIDLast', label: 'Created Session ID (last)', section: SECTION_OPTION_SEGMENT.CREATED_SECTION, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSessionIDLastND', label: 'Created Session ID <br>(last non-direct)', section: SECTION_OPTION_SEGMENT.CREATED_SECTION, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSessionIDPath', label: 'Created Session ID (path)', section: SECTION_OPTION_SEGMENT.CREATED_SECTION, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR }, 
               { value: 'identifiedSessionIDPathND', label: 'Created Session ID <br>(path non-direct)', section: SECTION_OPTION_SEGMENT.CREATED_SECTION, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR }, 

               { value: 'userSourceFirstPersonCreated', label: 'User Source - First <br>(person created)', section: SECTION_OPTION_SEGMENT.CREATED_USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'userSourcePathPersonCreated', label: 'User Source - Path <br>(person created)', section: SECTION_OPTION_SEGMENT.CREATED_USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'userSourceLastPersonCreated', label: 'User Source - Last <br>(person created)', section: SECTION_OPTION_SEGMENT.CREATED_USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSourceFirstND', label: 'Non-Direct User Source - First <br>(person created)', section: SECTION_OPTION_SEGMENT.CREATED_USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR }, 
               { value: 'identifiedSourcePathND', label: 'Non-Direct User Source - Path <br>(person created)', section: SECTION_OPTION_SEGMENT.CREATED_USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR }, 
               { value: 'identifiedSourceLastND', label: 'Non-Direct User Source - Last <br>(person created)', section: SECTION_OPTION_SEGMENT.CREATED_USER_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR }, 

               { value: 'creationDate', label: 'Creation Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'lastConversionDate', label: 'Last Conversion Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'lastEventDate', label: 'Last Event Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'firstActiveAt', label: 'First Active', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'identifiedHour', label: 'Creation Hour', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'identifiedDayOfWeek', label: 'Creation Day of Week', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'identifiedMonth', label: 'Creation Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'identifiedYearMonth', label: 'Creation Year Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'identifiedQuarter', label: 'Creation Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'identifiedYearQuarter', label: 'Creation Year Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },

               { value: 'userId', label: 'User ID', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
               // { value: 'personID', label: 'Person ID', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
               // { value: 'identifiedAt', label: 'Identified Timestamp', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },

               // { value: 'identifiedSources.sessionID', label: 'Source Session ID', section: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               // { value: 'identifiedSources.source', label: 'Source', section: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               // { value: 'identifiedSources.timestamp', label: 'Timestamp', section: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.referrerHostname', label: 'Referrer Hostname', section: SECTION_OPTION_SEGMENT.REFERRER, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.referrerOriginPath', label: 'Referrer Origin Path', section: SECTION_OPTION_SEGMENT.REFERRER, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.referrerPath', label: 'Referrer Path', section: SECTION_OPTION_SEGMENT.REFERRER, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.utm_medium', label: 'utm_medium', section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.utm_source', label: 'utm_source', section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.utm_campaign', label: 'utm_campaign', section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.utm_term', label: 'utm_term', section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.utm_content', label: 'utm_content', section: SECTION_OPTION_SEGMENT.UTM, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.gclid', label: 'Click IDs (gclid)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.fbclid', label: 'Click IDs (fbclid)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.fbp', label: 'Click IDs (fbp)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.fbc', label: 'Click IDs (fbc)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.msclkid', label: 'Click IDs (msclkid)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.li_fat_id', label: 'Click IDs (li_fat_id)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.ttclid', label: 'Click IDs (ttclid)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.dclid', label: 'Click IDs (dclid)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.srsltid', label: 'Click IDs (srsltid)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources._ga', label: 'Click IDs (_ga)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },
               { value: 'identifiedSources.calltrk_user', label: 'Click IDs (calltrk_user)', section: SECTION_OPTION_SEGMENT.CLICK_ID, sectionParent: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR },

               { value: 'isTargetAccount', label: 'Is Target Account', section: SECTION_OPTION_SEGMENT.TARGET_ACCOUNT },
               { value: 'targetAccountDomain', label: 'Target Account Domain', section: SECTION_OPTION_SEGMENT.TARGET_ACCOUNT },
          ],
          metric: [
               { value: 'eventCount', label: 'Events', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'sessionCount', label: 'Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'avgSessionDuration', label: 'Avg Session Duration', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'engagedSessionCount', label: 'Engaged Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'engagementRate', label: 'Engagement Rate', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'engagementScore', label: 'Lifetime Engagement Score', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'bouncedSessionCount', label: 'Bounced Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'bounceRate', label: 'Bounce Rate', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'pageViewCount', label: 'Pageviews', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'avgPageViewsPerSession', label: 'Avg Pageviews / Session', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },

               { value: 'sourcePathCount', label: 'Source Path Count', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'noneDirectSourcePathCount', label: 'Non-Direct Source Path Count', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },

               { value: 'conversionCount', label: 'All Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'primaryConversionCount', label: 'Primary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'secondaryConversionCount', label: 'Secondary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'conversionRateAll', label: 'Conv Rate (All Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'conversionRatePrimary', label: 'Conv Rate <br>(Primary Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'conversionRateSecondary', label: 'Conv Rate <br>(Secondary Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },

               { value: 'revealedCompanyCount', label: 'Revealed Company Count', section: SECTION_OPTION_SEGMENT.COMPANY_DETAILS },
               { value: 'identifiedSourcePathCount', label: 'Created Source Path Count', section: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR }, 
               { value: 'identifiedSourcePathCountND', label: 'Created Source Path Count <br>(non-direct)', section: SECTION_OPTION_SEGMENT.PERSON_CREATED_SOURCE_INFOR }, 

          ],
     },
};

export const VARIABLE_PROPERTY_WEB_CONVERSION_SEGMENT = [
     // { value: 'conversionID', label: 'Conversion ID', type:'dimension', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
     { value: 'sessionID', label: 'Session ID', type:'dimension', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
     { value: 'userID', label: 'User ID', type:'dimension', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
     { value: 'date', label: 'Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'hour', label: 'Hour', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'dayOfWeek', label: 'Day of Week', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'month', label: 'Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
     { value: 'yearMonth', label: 'Year Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
     { value: 'quarter', label: 'Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'yearQuarter', label: 'Year Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 

     { value: 'conversionType', label: 'Conversion Type', section: SECTION_OPTION_SEGMENT.CONVERSIONS },

     { value: 'conversionValue', label: 'Conversion Value', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
     { value: 'conversionCurrency', label: 'Conversion Currency', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
     { value: 'conversionLocationHostname', label: 'Conversion Location (Hostname)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
     { value: 'conversionLocationOriginPath', label: 'Conversion Location <br>(Origin Path)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
     { value: 'conversionLocationURL', label: 'Conversion Location (URL)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSIONS },

     { value: 'createdSessionIDFirst', label: 'session ID - first', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SECTION, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSessionIDLast', label: 'session ID - last', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SECTION, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSessionIDPath', label: 'session ID - path', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SECTION, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
    
     { value: 'createdSourceFirst', label: 'userSource - first', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSourceLast', label: 'userSource - last', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSourcePath', label: 'userSource - path', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSessionIDFirstND', label: 'non-direct userSource first', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSourceLastND', label: 'non-direct userSource last', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },

     { value: 'createdSources.referrerHostname', label: 'Referrer Hostname', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE_REFERRER, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.referrerOriginPath', label: 'Referrer Origin Path', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE_REFERRER, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.referrerPath', label: 'Referrer Path', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE_REFERRER, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },

     // { value: 'createdSourceFirst', label: 'Created Source (first)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_SUMMARY },
     // { value: 'createdSourceFirstND', label: 'Created Source (first non-direct)', type:'dimension' },
     // { value: 'createdSessionIDLastND', label: 'Created Session ID (last non-direct)', type:'dimension' },
     // { value: 'createdSourcePathCount', label: 'Created Source Path Count', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_SUMMARY },
     // { value: 'createdSessionIDPathND', label: 'Created Session ID (path non-direct)', type:'dimension' },
     // { value: 'createdSourcePathND', label: 'Created Source (path non-direct)', type:'dimension' },
     // { value: 'createdSourcePathCountND', label: 'Created Source Path Count (non-direct)', type:'dimension' },
     // { value: 'createdSources.sessionID', label: 'Created Sources <br>(Source Session ID)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_SUMMARY },
     // { value: 'createdSources.timestamp', label: 'Created Sources <br>(Source Timestamp)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_SUMMARY },
     // { value: 'createdSources.source', label: 'Created Sources (Source)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_SUMMARY },
     // { value: 'createdSources.referrerHostname', label: 'Created Sources (Referrer Hostname)', type:'dimension' },
     // { value: 'createdSources.referrerOriginPath', label: 'Created Sources (Referrer Origin Path)', type:'dimension' },
     // { value: 'createdSources.referrerPath', label: 'Created Sources (Referrer Path)', type:'dimension' },

     { value: 'createdSources.landingPageOriginPath', label: 'Landing Page', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_LOCATION, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
    
     { value: 'createdSources.utmMedium', label: 'utm_medium', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.utmSource', label: 'utm_source', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.utmSourceMedium', label: 'utm_source / utm_medium', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.utmCampaign', label: 'utm_campaign', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.utmTerm', label: 'utm_term', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.utmContent', label: 'utm_content', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     // { value: 'createdSources.otherUTMs', label: 'Other UTMs', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },

     { value: 'createdSources.clickIDs.gclid', label: 'Click IDs (gclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.fbclid', label: 'Click IDs (fbclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.fbp', label: 'Click IDs (fbp)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.fbc', label: 'Click IDs (fbc)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.msclkid', label: 'Click IDs (msclkid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.li_fat_id', label: 'Click IDs (li_fat_id)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.ttclid', label: 'Click IDs (ttclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.dclid', label: 'Click IDs (dclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.srsltid', label: 'Click IDs (srsltid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.clickIDs._ga', label: 'Click IDs (_ga)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.calltrk_user', label: 'Click IDs (calltrk_user)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_INFOR },
  
     // { value: 'createdSources.engagementScore', label: 'Created Source Engagement Score', type:'metric' },
     // { value: 'createdSources.weights.engagement', label: 'Created Sources Weights (Engagement)', type:'metric' },
     // { value: 'createdSources.weights.engagementND', label: 'Created Sources Weights (Engagement Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.firstTouch', label: 'Created Sources Weights (First Touch)', type:'metric' },
     // { value: 'createdSources.weights.firstTouchND', label: 'Created Sources Weights (First Touch Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.lastTouch', label: 'Created Sources Weights (Last Touch)', type:'metric' },
     // { value: 'createdSources.weights.lastTouchND', label: 'Created Sources Weights (Last Touch Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.uShaped', label: 'Created Sources Weights (U-Shaped)', type:'metric' },
     // { value: 'createdSources.weights.uShapedND', label: 'Created Sources Weights (U-Shaped Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.linear', label: 'Created Sources Weights (Linear)', type:'metric' },
     // { value: 'createdSources.weights.linearND', label: 'Created Sources Weights (Linear Non Direct)', type:'metric' },
];

export const VARIABLE_PROPERTY_ECOMMERCE_SEGMENT = [
     { value: 'eventID', label: 'Event ID', type:'dimension', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
     { value: 'sessionID', label: 'Session ID', type:'dimension', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
     { value: 'userID', label: 'User ID', type:'dimension', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },

     { value: 'date', label: 'Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'hour', label: 'Hour', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'dayOfWeek', label: 'Day of Week', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'month', label: 'Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
     { value: 'yearMonth', label: 'Year Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
     { value: 'quarter', label: 'Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
     { value: 'yearQuarter', label: 'Year Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 

     // { value: 'eventType', label: 'Event Type', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceValue', label: 'Total Value', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommercePurchaseRevenue', label: 'Purchase Revenue', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     // { value: 'eCommerceRefundValue', label: 'Refund Value', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceShippingValue', label: 'Shipping Value', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     // { value: 'ecommerceCartLevelCoupon', label: 'Cart Level Coupon', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'ecommerceCoupon', label: 'Coupon', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     // { value: 'ecommerceCartLevelDiscount', label: 'Cart Level Discount', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'ecommerceDiscount', label: 'Discount', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceTaxValue', label: 'Tax Value', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceCurrency', label: 'Currency', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceTotalItemQuantity', label: 'Total Item Quantity', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     // { value: 'eCommerceUniqueItemCount', label: 'Unique Item Count', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceTransactionID', label: 'Transaction ID', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceItemListID', label: 'Item List ID', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceItemListName', label: 'Item List Name', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eventLocationHostname', label: 'Event Location (Hostname)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eventLocationOriginPath', label: 'Event Location (Origin Path)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eventLocationURL', label: 'Event Location (URL)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceItems.itemID', label: 'Item ID', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemName', label: 'Item Name', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemBrand', label: 'Item Brand', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemVariants.item_variant', label: 'Item Variant', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemCategories.item_category', label: 'Item Category', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemCategories.item_category1', label: 'Item Category 1', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemCategories.item_category2', label: 'Item Category 2', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemCategories.item_category3', label: 'Item Category 3', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemCategories.item_category4', label: 'Item Category 4', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemPrice', label: 'Item Price', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemQuantity', label: 'Item Quantity', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemRevenue', label: 'Item Revenue', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.coupon', label: 'Item Coupon', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.discount', label: 'Item Discount', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemListID', label: 'Item List ID', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemListName', label: 'Item List Name', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },

     { value: 'createdSessionIDFirst', label: 'session ID - first', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SECTION, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSessionIDLast', label: 'session ID - last', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SECTION, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSessionIDPath', label: 'session ID - path', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SECTION, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
    
     { value: 'createdSourceFirst', label: 'userSource - first', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSourceLast', label: 'userSource - last', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSourcePath', label: 'userSource - path', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSourceFirstND', label: 'non-direct userSource first', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSessionIDLastND', label: 'non-direct userSource last', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },

     // { value: 'createdSourceFirstND', label: 'Created Source (first non-direct)', type:'dimension' },
     // { value: 'createdSessionIDFirstND', label: 'Created Session ID (first non-direct)', type:'dimension' },
     // { value: 'createdSourceLastND', label: 'Created Source (last non-direct)', type:'dimension' },
     // { value: 'createdSessionIDLastND', label: 'Created Session ID (last non-direct)', type:'dimension' },
     // { value: 'createdSourcePathND', label: 'Created Source (path non-direct)', type:'dimension' },
     // { value: 'createdSessionIDPathND', label: 'Created Session ID (path non-direct)', type:'dimension' },
     // { value: 'createdSources.source', label: 'Created Sources (Source)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     // { value: 'createdSources.timestampUTC', label: 'Created Sources (Source Timestamp UTC)', type:'dimension' },
     // { value: 'createdSources.timestamp', label: 'Created Sources (Timestamp)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     // { value: 'createdSources.sessionID', label: 'Created Sources <br>(Source Session ID)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     // { value: 'createdSources.referrerHostname', label: 'Created Sources (Referrer Hostname)', type:'dimension' },
     // { value: 'createdSources.referrerOriginPath', label: 'Created Sources (Referrer Origin Path)', type:'dimension' },
     // { value: 'createdSources.referrerPath', label: 'Created Sources (Referrer Path)', type:'dimension' },

     { value: 'createdSources.referrerHostname', label: 'Referrer Hostname', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE_REFERRER, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.referrerOriginPath', label: 'Referrer Origin Path', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE_REFERRER, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.referrerPath', label: 'Referrer Path', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_SOURCE_REFERRER, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },

     { value: 'createdSources.landingPageOriginPath', label: 'Landing Page', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_LOCATION, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },

     { value: 'createdSources.utmMedium', label: 'utm_medium', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.utmSource', label: 'utm_source', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.utmSourceMedium', label: 'utm_source / utm_medium', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.utmCampaign', label: 'utm_campaign', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.utmTerm', label: 'utm_term', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.utmContent', label: 'utm_content', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     // { value: 'createdSources.otherUTMs', label: 'Other UTMs', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_UTM, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     
     { value: 'createdSources.clickIDs.gclid', label: 'Click IDs (gclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.fbclid', label: 'Click IDs (fbclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.fbp', label: 'Click IDs (fbp)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.fbc', label: 'Click IDs (fbc)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.msclkid', label: 'Click IDs (msclkid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.li_fat_id', label: 'Click IDs (li_fat_id)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.ttclid', label: 'Click IDs (ttclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.dclid', label: 'Click IDs (dclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.srsltid', label: 'Click IDs (srsltid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.clickIDs._ga', label: 'Click IDs (_ga)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },
     { value: 'createdSources.clickIDs.calltrk_user', label: 'Click IDs (calltrk_user)', type:'dimension', section: SECTION_OPTION_SEGMENT.CREATED_CLICK_IDS, sectionParent: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_INFOR },

     // { value: 'createdSources.engagementScore', label: 'Created Source Engagement Score', type:'metric' },
     // { value: 'createdSources.weights.engagement', label: 'Created Sources Weights (Engagement)', type:'metric' },
     // { value: 'createdSources.weights.engagementND', label: 'Created Sources Weights (Engagement Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.firstTouch', label: 'Created Sources Weights (First Touch)', type:'metric' },
     // { value: 'createdSources.weights.firstTouchND', label: 'Created Sources Weights (First Touch Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.lastTouch', label: 'Created Sources Weights (Last Touch)', type:'metric' },
     // { value: 'createdSources.weights.lastTouchND', label: 'Created Sources Weights (Last Touch Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.uShaped', label: 'Created Sources Weights (U-Shaped)', type:'metric' },
     // { value: 'createdSources.weights.uShapedND', label: 'Created Sources Weights (U-Shaped Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.linear', label: 'Created Sources Weights (Linear)', type:'metric' },
     // { value: 'createdSources.weights.linearND', label: 'Created Sources Weights (Linear Non Direct)', type:'metric' },
];

export const SEGMENT_ALL_DATA_DEFAULT_NAME = 'All Data'